import React from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonImg
} from '@ionic/react';
import './ActivitiesModal.css';

interface Activity {
  title: string;
  description: string;
  imageUrl?: string;
}

interface ActivitiesModalProps {
  isOpen: boolean;
  onClose: () => void;
  activities: Activity[];
}

const ActivitiesModal: React.FC<ActivitiesModalProps> = ({ isOpen, onClose, activities }) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Actividades</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="activities-modal-content">
        <IonList>
          {activities.map((activity, index) => (
            <IonItem key={index} className="activity-card">
              {activity.imageUrl && <IonImg src={activity.imageUrl} alt={activity.title} />}
              <IonLabel>
                <h2 className="activity-card-title">{activity.title}</h2>
                <p className="activity-card-description">{activity.description}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ActivitiesModal;
