import React from 'react';
import { IonSearchbar } from '@ionic/react';
import '../components/SearchPlatillo.css'
import { useTranslation } from 'react-i18next';

function SearchPlatillo() {
  const [ t , i18n ] = useTranslation("global");
  return (
    <>
      <IonSearchbar showCancelButton="focus" class="custom" placeholder={t("restaurant.searchBar")} ></IonSearchbar>
    </>
  );
}
export default SearchPlatillo;