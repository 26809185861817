import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import "./Tab4.css"
import { useTranslation } from 'react-i18next';

const Tab3: React.FC = () => {
    const [ t , i18n ] = useTranslation("global");
    return (
        <IonPage>
            <IonHeader color="light" class="ion-no-border ion-text-center">
                <IonToolbar className='profileToolbar'>
                    <IonTitle className='nombre-marca'>{t("profile.title")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className='content-img'>
                    <img className='avatar' src='https://vivolabs.es/wp-content/uploads/2022/03/perfil-mujer-vivo.png'></img>
                </div>
                <p className='ion-text-center ion-text-wrap text-profile texto-profile-nombre'>Maria Rosas Palacios</p>
                <IonGrid>
                    <IonRow className='ion-padding-start ion-padding-end'>
                        <IonCol className="ion-text-center text-profile-sub" size='6'>{t("profile.since")}</IonCol>
                        <IonCol className="ion-text-center text-profile-sub" size='6'>{t("profile.stars")}</IonCol>
                    </IonRow>
                    <IonRow className='ion-padding-start ion-padding-end'>
                        <IonCol className="ion-text-center " size='6'><p className='text-profile-info'>05/Septiembre/2023</p></IonCol>
                        <IonCol className="ion-text-center" size='6'><p className='text-profile-info'>55</p></IonCol>
                    </IonRow>
                </IonGrid>
                <div className="ion-no-border acomodar-profile ion-padding-bottom">
                    <img className='ion-margin-top' src="https://upload.wikimedia.org/wikipedia/commons/d/d7/Commons_QR_code.png" alt="QR-code"></img>
                    <IonButton className='ion-margin-top fondo-boton-profile' shape='round'>{t("profile.button")}</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Tab3;