import { IonList, IonItem, IonThumbnail, IonLabel, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert,useIonRouter,useIonViewDidEnter,useIonViewWillEnter,withIonLifeCycle } from '@ionic/react';
import React, { useEffect, useState} from 'react';
import  {
    BarcodeScanner,

  } from '@capacitor-mlkit/barcode-scanning';

import "./NearArcheology.css"
import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack,alarmSharp,linkSharp, medalSharp } from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import { changeLanguage } from 'i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const NearArcheology: React.FC<RouteComponentProps> = ({match}) => {
    const [t,i18n] = useTranslation("global");
    const [err,setErr] = useState<string>();
    const [present] = useIonAlert();
    const history = useHistory();
    const router = useIonRouter();

    console.log('Language: ',i18n.language);

    const langEnable = i18n.language;
    let mtitle="", mdesc="", mcurrent="", luginteres="", mname1 = "", mhowgochit1 = "", mname2 = "", mhowgochit2 = "";

    switch(langEnable){
        case 'es':
            mtitle = 'Asistente de viaje';
            mdesc = 'En este momento Usted se encuentra en la Riviera Maya. En el Estado de Quintanma Roo, México. Quintana Roo es una región única en el mundo, diversa en cuanto a destinos y atractivos turísticos. Ofrece mar azul, playas de arena blanca, selva tropical, zonas arqueológicas, cenotes, ríos subterráneos, islas, lagunas, cultura Maya viva e historia.';
            mcurrent = 'Como su nombre lo indica, la Riviera Maya fue habitada originalmente por el pueblo maya desde el 200 a. C. Si bien las ciudades que florecieron en la zona no se comparaban a Tikal , Calakmul o Chichen Itzá en cuanto a infraestructura, aún seguían teniendo construcciones en la que se emplearon bases matemáticas y alto nivel de precisión, artes e ingeniería, heredadas de la influencia del Petén y la región Puuc.';
            luginteres = "Lugares de interés cerca de donde te encuentras...";
            mname1 = "Chichen Itza, Quintana Roo, México";
            mhowgochit1 = "A 115 kilómetros al este de Mérida, Yucatán, se ubica Chichén Itzá, una de las urbes más importantes de la civilización maya. La UNESCO la nombró Patrimonio Cultural de la Humanidad, y subrayó la excelencia artística de sus grandes monumentos, verdaderas obras maestras de la arquitectura mesoamericana. También destacó la importancia que tuvo en una amplia área geográfica de Yucatán y su valor como el ejemplo más notable de la civilización maya-tolteca en la península. Aparte, se trata quizá del ejemplo más claro del dominio de la astronomía que tuvieron los mayas. Hay tres opciones principales para llegar a Chichén Itzá desde donde te encuentras (Riviera Maya): en coche, en autobús o con una furgoneta de tipo shuttle. Si quieres ir por tu cuenta, puedes alquilar un coche y dirigirte hacia Tinum (localidad donde se encuentra Chichén Itzá) por el autopista.";
            mname2 = "Tulum, Quintana Roo, México";
            mhowgochit2 = "Una de las principales actividades de los turistas que visitan Playa del Carmen es  recorrer la Riviera Maya y conocer las paradisiacas playas de Tulum y por supuesto su zona arqueológica, que es de las más visitadas de México. Tulum se encuentra a 45 minutos de Playa del Carmen y a 1 hora y media de Cancún aproximadamente, así que es muy común escuchar la pregunta ¿Cómo ir de Playa del Carmen a Tulum?, aquí de decimos las 3 formas más comunes para llegar a Tulum desde Playa del Carmen: En autobus, es una de las opciones más comunes por su facilidad y comodidad, ya que los camiones salen aproximadamente cada 20 minutos. Rentando un auto, porque la carretera de Playa del Carmen hacia Tulum es conocida por sus innumerables cenotes y playas que puedes conocer en el recorrido, así que si vienes a conocer la Riviera Maya esta es la mejor opcion ya que puedes ir haciendo paradas continuas y disfrutar no solo de Tulum si no del recorrido, ademas es una excelente opción para ahorrar si viajan en grupo. Por último, puedes contratar un Tour, existen un sin fin de compañías que ofrecen el tour para la zona arqueológica de Tulum, ellos pasan por ti al hotel o departamento y te llevan de regreso, eso si, gastarás un poco más pero no te vas a preocupar por nada.";
            break;
        case 'en':
            mtitle = 'Travel assistant';
            mdesc = 'Right now you are in the Riviera Maya. In the State of Quintana Roo, Mexico. Quintana Roo is a unique region in the world, diverse in terms of destinations and tourist attractions. It offers blue sea, white sand beaches, rainforest, archaeological sites, cenotes, underground rivers, islands, lagoons, living Mayan culture and history.';
            mcurrent = 'As the name claims, the Riviera Maya was originally inhabited by the Mayan people as early as 200 BC. Although the cities that flourished in the area did not compare to Tikal, Calakmul or Chichen Itzá in terms of infrastructure, they still had constructions in which mathematical bases and a high level of precision, arts and engineering were used, inherited from the influence of the Petén and the Puuc region.';
            luginteres = "Places of interest near you...";
            mname1 = "Chichen Itza, Quintana Roo, Mexico";
            mhowgochit1 = "115 kilometers east of Merida, Yucatan, is Chichen Itza, one of the most important cities of the Mayan civilization. UNESCO named it a World Heritage Site, and underlined the artistic excellence of its great monuments, true masterpieces of Mesoamerican architecture. He also highlighted the importance it had in a wide geographical area of Yucatan and its value as the most notable example of the Maya-Toltec civilization in the peninsula. In addition, it is perhaps the clearest example of the Maya's mastery of astronomy. There are three main options to get to Chichen Itza from where you are (Riviera Maya): by car, by bus or with a shuttle van. If you want to go on your own, you can rent a car and head towards Tinum (the town where Chichen Itza is located) on the highway.";
            mname2 = "Tulum, Quintana Roo, Mexico";
            mhowgochit2 = "One of the main activities of tourists who visit Playa del Carmen is to tour the Riviera Maya and get to know the paradisiacal beaches of Tulum and of course its archaeological zone, which is one of the most visited in Mexico. Tulum is located 45 minutes from Playa del Carmen and approximately 1 hour and a half from Cancun, so it is very common to hear the question How to get from Playa del Carmen to Tulum, here are the 5 most common ways to get to Tulum from Playa del Carmen: By bus, it is one of the most common options due to its ease and comfort, as trucks leave approximately every 20 minutes. Renting a car, because the road from Playa del Carmen to Tulum is known for its countless cenotes and beaches that you can visit on the tour, so if you come to know the Riviera Maya this is the best option since you can make continuous stops and enjoy not only Tulum but also the tour,  It is also an excellent option to save if you are traveling in a group. Finally, you can hire a Tour, there are an endless number of companies that offer the tour for the archaeological zone of Tulum, they pick you up at the hotel or apartment and take you back, yes, you will spend a little more but you will not worry about anything.";
            break;
        case 'fr':
            mtitle = 'Assistant de voyage';
            mdesc = 'En ce moment, vous êtes sur la Riviera Maya. Dans l’État de Quintana Roo, au Mexique. Quintana Roo est une région unique au monde, diversifiée en termes de destinations et d’attractions touristiques. Il offre une mer bleue, des plages de sable blanc, une forêt tropicale, des sites archéologiques, des cenotes, des rivières souterraines, des îles, des lagunes, une culture et une histoire mayas vivantes.';
            mcurrent = 'Comme son nom l’indique, la Riviera Maya était à l’origine habitée par le peuple maya depuis 200 av. J.-C. Bien que les villes qui ont prospéré dans la région ne se comparent pas à Tikal, Calakmul ou Chichen Itzá en termes d’infrastructures, elles avaient encore des constructions dans lesquelles des bases mathématiques et un haut niveau de précision, d’art et d’ingénierie étaient utilisés, hérités de l’influence du Petén et de la région de Puuc.';
            luginteres = "Lieux d’intérêt près de chez vous...";
            mname1 = "Chichen Itza, Quintana Roo, Mexique";
            mhowgochit1 = "À 115 kilomètres à l’est de Mérida, dans le Yucatan, se trouve Chichen Itza, l’une des villes les plus importantes de la civilisation maya. L’UNESCO l’a classé au patrimoine mondial de l’humanité et a souligné l’excellence artistique de ses grands monuments, véritables chefs-d’œuvre de l’architecture mésoaméricaine. Il a également souligné l’importance qu’il avait dans une vaste zone géographique du Yucatan et sa valeur en tant qu’exemple le plus notable de la civilisation maya-toltèque dans la péninsule. De plus, c’est peut-être l’exemple le plus clair de la maîtrise de l’astronomie par les Mayas. Il existe trois options principales pour se rendre à Chichen Itza depuis l’endroit où vous vous trouvez (Riviera Maya) : en voiture, en bus ou avec une navette. Si vous souhaitez y aller par vos propres moyens, vous pouvez louer une voiture et vous diriger vers Tinum (la ville où se trouve Chichen Itza) sur l’autoroute.";
            mname2 = "Tulum, Quintana Roo, Mexique";
            mhowgochit2 = "L’une des principales activités des touristes qui visitent Playa del Carmen est de faire le tour de la Riviera Maya et de découvrir les plages paradisiaques de Tulum et bien sûr sa zone archéologique, qui est l’une des plus visitées du Mexique. Tulum est située à 45 minutes de Playa del Carmen et à environ 1 heure et demie de Cancun, il est donc très courant d’entendre la question Comment se rendre de Playa del Carmen à Tulum, voici les 5 moyens les plus courants de se rendre à Tulum depuis Playa del Carmen : En bus, c’est l’une des options les plus courantes en raison de sa facilité et de son confort, car les camions partent environ toutes les 20 minutes. Louer une voiture, car la route de Playa del Carmen à Tulum est connue pour ses innombrables cenotes et plages que vous pouvez visiter lors de la visite, donc si vous apprenez à connaître la Riviera Maya, c’est la meilleure option car vous pouvez faire des arrêts continus et profiter non seulement de Tulum mais aussi de la visite.  C’est également une excellente option à économiser si vous voyagez en groupe. Enfin, vous pouvez louer un Tour, il existe un nombre infini d’entreprises qui proposent le tour de la zone archéologique de Tulum, elles viennent vous chercher à l’hôtel ou à l’appartement et vous ramènent, oui, vous dépenserez un peu plus mais vous ne vous soucierez de rien.";
            break;
        case 'zh':
            mtitle = '旅助理';
            mdesc = '今在里维埃拉玛雅。 在墨西哥金塔纳罗奥州。 金塔纳罗奥州者,世界之独无二也,在目与旅游景点异。 供蔚海、白沙、热带雨林、考古遗址、天然井、地下河、岛屿、泻湖、生玛雅文史。';
            mcurrent = '顾名思义,里维埃拉玛雅自公元前 200 以来始居玛雅人。 虽蓬勃发展城邑基础设施不得与蒂卡尔、卡拉克穆尔、奇琴伊察比,然犹有数学之基,高精度艺之功,继佩滕普克之化。';
            luginteres = "近景点...";
            mname1 = "Chichen Itza, 金塔纳罗奥州, 墨西哥";
            mhowgochit1 = "尤卡坦州梅里达东115公里,奇琴伊察也,玛雅文明最重城之一。 联合国教科文组织列为世界遗产,而调其大古卓异,此中美洲之真杰也。 又强其尤卡坦半岛广地之要,以为半岛玛雅-托尔特克文明最显之价。此外或玛雅人天文之最明者也。以君所在(里维埃拉玛雅)往奇琴伊察有三要:开车、乘公共汽车、乘梭车。 若欲自往,可租一车,循高速公路往蒂努姆(奇琴伊察所在小镇)。";
            mname2 = "Tulum, 金塔纳罗奥州, 墨西哥 ";
            mhowgochit2 = "观普拉亚德尔卡门之游,一为游里维埃拉玛雅,知图卢姆之天堂海滩,固有考古之区,此墨西哥访问量之大者也。 图卢姆去普拉亚卡门 45 分钟路,去坎昆约 1 半小时,故时闻从普拉亚德尔卡门往图卢姆,以下从普拉亚德尔卡门往图卢姆者 5 最常法。乘公共汽车,以其轻舒,最为常选,以卡车约 20 分钟一班也。租一车,以普拉亚德尔卡门至图卢姆之道闻于天然井海滩,子可观于行旅,故君知里维埃拉玛雅,此为上选,以君连留,非唯享图卢姆,亦可享游也。  如君为团体旅,此亦省绝佳择。最后,足下租旅游团,有无数公司供图卢姆考古之游,接君于酒肆或公寓而归者,君当多花一钱,而君不以为虑。";
            break;
        case 'maya':
            mtitle = 'Áantaj náachil';
            mdesc = "Ti' le súutuka' tech Kula'an ti' le Riviera Maya. Ti' le noj u Quintanma Roo, México. Quintana Roo jach jump'éel región chen kaabe', ya'abach en cuanto a destinos yéetel atractivos turísticos. K'u'ubul k'áak'náabo' ch'ooj, playas arena sak, u k'áaxil tropical, zonas arqueológicas, ts'ono'oto'ob, buuts' subterráneos, islas, lagunas, miatsil maaya viva yéetel k'ajláayo'.";
            mcurrent = "Bix u k'aaba' le indica, le Riviera Maya bin habitada originalmente tumen le kaajo' maaya tak le 200 u. kuxtal Wa bien le ciudades florecieron ti' le kúuchila' ma' u comparaban u Tikal, Calakmul wa Chichen Itzá en cuanto a meyaje', láayli' seguían teniendo construcciones ti' le ba'ax ku emplearon bases matemáticas yéetel ka'anal nivel u precisión, Arteso' yéetel ingeniería, heredadas u influencia le Petén yéetel le petenil Puuc.";
            break;
    }

      const navigate = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        router.push('/app/tabs');
    };
      
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className='custom'>
                        <IonButtons slot='start'>
                          <IonButton onClick={(event) => navigate(event)} color='light'>
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                      <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen={true} className="ion-padding">
                    <div className="content-museo">
                        <div className="title-name">
                            <h1>{mtitle}</h1>
                        </div>
                    </div>
                    <p>
                    {mdesc}
                    </p>
                    <p>
                    {mcurrent}
                    </p>
                    <IonAccordionGroup>
                        <h2>{luginteres}</h2>
                        <IonAccordion value="first">
                            <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="https://www.yucatan.gob.mx/docs/galerias/chichen_itza/1.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname1}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit1}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`https://www.yucatan.gob.mx/docs/galerias/chichen_itza/1.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://www.mexicodesconocido.com.mx/wp-content/uploads/2018/05/que-hacer-en-merida-chichen-itza-flickr-james-clemens.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://www.mexicodesconocido.com.mx/wp-content/uploads/2016/10/cenotes-en-yucatan-chichen-itza-flickr-ricardo-quijada.jpg`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="second">
                        <IonItem slot="header" color="light">
                                <IonThumbnail>
                                    <img alt="..." src="https://www.gob.mx/cms/uploads/article/main_image/85362/Tulum-aerea-web.jpg" />
                                </IonThumbnail>
                                <IonLabel class="padleft7px">
                                    <h3>{mname2}</h3>
                                </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                <p>{mhowgochit2}</p>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                >
                                    <SwiperSlide><img className='imgintro' src={`https://www.gob.mx/cms/uploads/article/main_image/85362/Tulum-aerea-web.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://www.gob.mx/cms/uploads/image/file/523369/Quintana-roo_Tulum_gran-cenote-de-tulum_web.jpg`}/></SwiperSlide>
                                    <SwiperSlide><img className='imgintro' src={`https://cdn.getyourguide.com/img/tour/2ee3f797a20bf4e4a08d893e4892fec7a3cb46fd0c74f807e04b5eac846f71c1.jpeg/132.webp`}/></SwiperSlide>
                                </Swiper>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                                
                </IonContent>


            </IonPage>
        );
    
};

export default withIonLifeCycle(NearArcheology);