import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonTitle, IonToolbar, IonicSlides } from '@ionic/react';

import location from '../assets/location-pin-dark.svg'
import './CardArq.css'
import { ArqProps } from '../../src/interfaces';
import { alarmSharp, arrowBack, callSharp, linkSharp, medalSharp } from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import closePage from '../assets/close-circle.svg'

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';

function CardArq({nombre, traduccion, municipio, descripcion, horario, sitio_web, precio, tipo, promocion, urlImage} : ArqProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  
  return (
    <>
      <IonCard id='cardArt' color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={nombre} src={urlImage} />
        <IonCardHeader>
          <IonCardTitle>{nombre}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
            <div className="localidad-card">
                        <div className="icon-loc">
                            <IonImg
                                src={location}            
                                alt="Location"
                            ></IonImg>
                        </div>
                        <div className="lugar">
                            {municipio}
                        </div>
            </div>
            <div className="score">
              ${precio}.00 MXN
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='art-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-arq'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-arq' onClick={() => setIsOpen1(false)}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
              <div className="image-potada">
                <img className='header-modal-img-arq'
                  src={urlImage}
                  alt={nombre}
                />

              </div>
            <IonTitle>
            <div className="title">
                    <div className="localidad">
                        <div className="icon-loc">
                            <IonImg
                                src={location}            
                                alt="Location"
                            ></IonImg>
                        </div>
                        <div className="lugar">
                            {municipio}
                        </div>
                    </div>
                    <div className="precio">
                        <div className="cantidad">
                            ${precio}.00
                        </div>
                        <div className="moneda">
                            Mxn
                        </div>
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        <div className="content-arq paddingt_7">
            <div className="title-name paddingt_7">
                <h1>{nombre}</h1>
            </div>
            <div className="description">
                <p>{traduccion}</p>
            </div>
        </div>
        <p>
            {descripcion}
        </p>

        <IonList className='Incluye'>
            <h3>Rapports</h3>
            <IonItem>
              <IonIcon icon={alarmSharp} slot="start"></IonIcon>
              <IonLabel>{horario}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={linkSharp} slot="start"></IonIcon>
              <IonLabel>{sitio_web}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={medalSharp} slot="start"></IonIcon>
              <IonLabel>{promocion}</IonLabel>
            </IonItem>
        </IonList>


        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default CardArq;
