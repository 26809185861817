import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonAlert,useIonRouter,useIonViewDidEnter,useIonViewWillEnter,withIonLifeCycle } from '@ionic/react';
import React, { useEffect, useState} from 'react';
import  {
    BarcodeScanner,

  } from '@capacitor-mlkit/barcode-scanning';

import "./ScannerQR.css"
import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack } from 'ionicons/icons';
import {useTranslation} from 'react-i18next'


const ScannerQR: React.FC<RouteComponentProps> = ({match}) => {
    const [t,i18n] = useTranslation("global");
    const [err,setErr] = useState<string>();
    const [present] = useIonAlert();
    const history = useHistory();
    const router = useIonRouter();

    const startScan = async () => {
        document.querySelector('body')?.classList.add('barcode-scanner-active');
          const granted = await requestPermissions();
          if (!granted) {
            present({
                header: 'Permiso Denegado',
                message: 'Dele permiso a la camara de scannear',
                buttons: ['OK'],
              })
            return;
          }
          const listener = await BarcodeScanner.addListener(
            'barcodeScanned',async result => {
                try {
                    await listener.remove();
                    await BarcodeScanner.stopScan();
                    document.querySelector('body')?.classList.remove('barcode-scanner-active');
                    present({
                        header: 'Resultado',
                        message: result.barcode.rawValue,
                        buttons: [ {
                            text: 'Resultado',
                            role: 'ok',
                            handler: () => {
                                router.push('/app/tabs/tab1','back');
                            },
                          }],
                    });
                } catch (error) {
                    console.log('No hay Scaner');
                }
            },
          );
        // Start the barcode scanner
        await BarcodeScanner.startScan();    
      }

      const stopScan = async () => {
        try {
            await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
        document.querySelector('body')?.classList.remove('barcode-scanner-active');
        router.push('/app/tabs/tab1','back');
        } catch (error) {
            document.querySelector('body')?.classList.remove('barcode-scanner-active');
            router.push('/app/tabs/tab1','back');
        }
      };

      const requestPermissions = async () => {
        const { camera } = await BarcodeScanner.requestPermissions();
        return camera === 'granted' || camera === 'limited';
      };

      const navigate = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        stopScan();
        router.push('/app/tabs');
    };

      useIonViewWillEnter(()=>{
        startScan();
      })

      useEffect(() => {
        startScan();

      }, [])
      
    
        return (
            <IonPage>
                <IonHeader className='ion-no-border toolbar-scan'>
                    <IonToolbar className='toolbar-scan ion-no-border'>
                    <IonButtons slot='start'>
                          <IonButton onClick={(event) => navigate(event)} color='light'>
                              <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                          </IonButton>
                      </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className='ocultar'>
                <div className='scaner-box'>
                <IonText color='light ' className='texto-scaner ion-text-center'> Coloque el codigo QR en la caja del escaner</IonText>
                    <div className="scanner-container-line">
                        <div className="scan-line"
                        ></div>
                    </div>
                </div>
                </IonContent>
            </IonPage>
        );
    
};

export default withIonLifeCycle(ScannerQR);