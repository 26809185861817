import React, { useState } from "react";

import '../components/ProgressRewards.css'
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg} from '@ionic/react';
import starFilled from '../assets/star-filled.svg'
import {useTranslation} from 'react-i18next'

const Experience = ({puntos} : {puntos:number}) => {
    const percent = puntos + "%";
    console.log("Porcentaje de puntos = "+percent)
    const [isOpen, setIsOpen] = useState(false);
    const [t,i18n] = useTranslation("global");
    return (
        <>
            <IonCard color="light" onClick={() => setIsOpen(true)}>
                <IonCardHeader className="rewards-titles">
                    <IonCardTitle >{puntos} <IonImg class='star-img' src={starFilled} alt="The Wisconsin"></IonImg> </IonCardTitle>
                    <IonCardSubtitle className="rewards-subtitle">{t("Tab3.titlePoints")}</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                    <div className="progress-line" >
                        <span style={{width:percent}}></span>
                    </div>
                </IonCardContent>
            </IonCard>

            {/* <IonModal isOpen={isOpen}>
                <IonHeader>
                    <IonToolbar>
                    <IonTitle>Modal</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
                    </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus quos
                    reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum qui.
                    Eaque, dicta.
                    </p>
                </IonContent>
            </IonModal> */}
        </>
    )
}

export default Experience;