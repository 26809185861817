import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonTitle, IonToolbar, IonicSlides } from '@ionic/react';
import './CardHospedaje.css'
import image from '../assets/map-pin.svg'
import Star from './Tab2/starRating'

import { HospedajeProps } from '../interfaces/index';
import { arrowBack, callSharp, linkSharp } from 'ionicons/icons';
import closePage from '../assets/close-circle.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';

function CardHospedaje({nombre, direccion, telefono, precio, urlImage, resena, calificacion, incluye, imgActividades} : HospedajeProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  
  return (
    <>
      <IonCard color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={nombre} src={urlImage} />
        <IonCardHeader>
          <IonCardTitle>{nombre}</IonCardTitle>
          <div className='container'>
              <div className="icon">
                <IonImg
                  src={image}            
                  alt="Location"
                ></IonImg>
                {/* <IonIcon icon="../assets/map-pin.svg" slot="end"></IonIcon> */}
              </div>
              <div className="ubicacion">
                <IonCardSubtitle>{direccion}</IonCardSubtitle>
              </div>
          </div>
        </IonCardHeader>

        <IonCardContent>
            <div className="score">
              <Star 
                score={calificacion}
              ></Star>
            </div>
            <div className="open">
              <p>Tel: {telefono}</p>
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='art-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-hosp'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-hosp' onClick={() => setIsOpen1(false)}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
            <IonImg className='header-modal-img-hosp'
              src={urlImage}
              alt={nombre}
            ></IonImg>
            <IonTitle>
                <div className="title">
                    <div className="precio">
                         {telefono}
                        {/* <div className="cantidad">
                        desde ${precio}.00
                        </div>
                        <div className="moneda">
                            Mxn
                        </div> */}
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
        <h1>{nombre}</h1>
        <div className='container'>
            <div className="icon">
                <IonImg
                  src={image}            
                  alt="Location"
                ></IonImg>
                {/* <IonIcon icon="../assets/map-pin.svg" slot="end"></IonIcon> */}
            </div>
            <div className="ubicacion">
                <h5>
            {direccion}
                </h5>
            </div>
        </div>
              <Swiper
                // spaceBetween={5}
                modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
                autoplay={true}
                keyboard={true}
                pagination={{
                  dynamicBullets: true,
                }}
                scrollbar={true}
                zoom={true}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {imgActividades.map((element, index) => 
            <>
              <SwiperSlide>
                <div id="swiper-ioncard">
                  <img className="img-swiper" alt='Hola' src={element} />
                </div>
                {/* <IonCard id='swiper-ioncard'>
                  <IonCardHeader>
                     <IonCardTitle>{actividades[index]}</IonCardTitle>
                  </IonCardHeader>
                </IonCard> */}
              </SwiperSlide>
                </>
              )
              }
              </Swiper>
        
        <p>
            {resena}
        </p>

        <IonList className='Incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Incluye </IonLabel>
          </IonListHeader>
          {
            incluye.map(element =>
              <>
                <IonItem>
                  <IonLabel>{element}</IonLabel>
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default CardHospedaje;
