import { IonButton, IonButtons, IonContent, IonHeader, IonThumbnail, IonImg, IonItem, IonLabel, IonList, IonTitle,  IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonPopover } from '@ionic/react';
import React, {  useState } from 'react';
import { IonIcon } from '@ionic/react';
import { alarmSharp, linkSharp, medalSharp,} from 'ionicons/icons';
import { ZonaRestProps } from '../interfaces';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'

import {FreeMode, Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

// import required modules

import closePage from '../assets/close-circle.svg'
import location_white from '../assets/location-pin.svg'


//Estilos de cada carta
import './CardRestaurant.css'

// import required modules
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';


const RestaurantModal = ({
    marker,
    onDismiss,
  }: {
    marker : ZonaRestProps;
    onDismiss: () => void;
  }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const openHtmlPage = (event: React.MouseEvent<HTMLIonButtonElement>) => {
        event.preventDefault();
        console.log("Opening");
        window.open('https://www.concanaco.com.mx', '_blank');
      };

    return (
        <>
           <IonHeader className='museo-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-museo'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-museo' onClick={() => onDismiss()}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
              <div className="image-potada">
                <img className='header-modal-img-museo'
                  src={(marker.urlImagen[0])}
                  alt={marker.nombre}
                />

              </div>
            <IonTitle>
            <div className="title">
                    <div className="localidad">
                        <div className="icon-loc">
                            <IonImg
                                src={location_white}            
                                alt="Location_white"
                            ></IonImg>
                        </div>
                        <div className="lugar">
                            {marker.municipio}
                        </div>
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        <div className="content-museo">
            <div className="title-name">
                <h1>{marker.nombre}</h1>
            </div>
        </div>
        <p>
            {marker.descripcion}
        </p>

        {/*
        <div>
        <Swiper autoplay={true} slidesPerView={4} spaceBetween={10} freeMode={true} pagination={{ clickable: true}} modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]} className="">
            <IonContent>
            <SwiperSlide> 
                <IonCard id='cardDrSimi' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Dr Simi"} src={"https://www.fundaciondrsimi.org.mx/assets/images/logo_fundacionDrSimi.png"} />
                </IonCard>
            </SwiperSlide>
            </IonContent>
            <IonContent>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://www.gob.mx/cms/uploads/action_program/main_image/28692/post_gobmx_acciones_bg_01.jpg"} />
                </IonCard>
            </SwiperSlide>
            </IonContent>
            <IonContent>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://materialesvaldez.mx/assets/img/pago/fonacot.jpeg"} />
                </IonCard>
            </SwiperSlide>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://upload.wikimedia.org/wikipedia/commons/b/ba/IMSS_Logos%C3%ADmbolo.png"} />
                </IonCard>
            </SwiperSlide>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://www.montepiedad.com.mx/storage/2023/01/logo.jpg"} />
                </IonCard>
            </SwiperSlide>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://upload.wikimedia.org/wikipedia/commons/2/2e/Logo_de_la_Loteria_Nacional_%28sin_texto%29.svg"} />
                </IonCard>
            </SwiperSlide>
            </IonContent>
        </Swiper>
        </div>
        */}

        <IonAccordionGroup>
            <h3>Platillos</h3>
            <IonAccordion value="first">
                <IonItem slot="header" color="light">
                    <IonThumbnail>
                        <img alt="..." src="https://tipsparatuviaje.com/wp-content/uploads/2017/07/1.-Pescado-a-la-Veracruzana.jpg" />
                    </IonThumbnail>
                    <IonLabel class="padleft7px">
                        <h4>Ricos guisos de Pescado</h4>
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <p>El Pescado a la Veracruzana es un platillo que simboliza el encuentro entre las dos culturas, la europea y la americana.</p>
                    <IonList className='Incluye'>
                        <h3>Ingredientes</h3>
                        <IonItem>
                            <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://www.gob.mx/cms/uploads/article/main_image/110952/WhatsApp_Image_2021-07-09_at_20.34.59.jpeg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Filete de pescado blanco</IonLabel>
                        </IonItem>

                        <IonItem>
                        <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://bd3-tuhuerto.s3.amazonaws.com/productos/20221206171437-1946.jpeg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Chiles Güeros</IonLabel>
                        </IonItem>

                        <IonItem>
                        <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://molineraelprogreso.com/img/aceitunas/verde-hueso.jpg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Aceitunas, alcaparras y orégano</IonLabel>
                        </IonItem>
                    </IonList>

                </div>
            </IonAccordion>
            <IonAccordion value="second">
            <IonItem slot="header" color="light">
                    <IonThumbnail>
                        <img alt="..." src="https://tipsparatuviaje.com/wp-content/uploads/2017/07/2.-Arroz-a-la-tumbada.jpg" />
                    </IonThumbnail>
                    <IonLabel class="padleft7px">
                        <h4>Estupendo Arroz</h4>
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <p>Es un símbolo culinario de la ciudad veracruzana de Alvarado y la comida más consumida en el estado durante la Semana Santa.</p>
                    <IonList className='Incluye'>
                        <h3>Ingredientes</h3>
                        <IonItem>
                            <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://www.gob.mx/cms/uploads/article/main_image/110952/WhatsApp_Image_2021-07-09_at_20.34.59.jpeg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Filete de pescado blanco</IonLabel>
                        </IonItem>

                        <IonItem>
                        <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://bd3-tuhuerto.s3.amazonaws.com/productos/20221206171437-1946.jpeg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Chiles Güeros</IonLabel>
                        </IonItem>

                        <IonItem>
                        <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://molineraelprogreso.com/img/aceitunas/verde-hueso.jpg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Aceitunas, alcaparras y orégano</IonLabel>
                        </IonItem>
                    </IonList>
                </div>

            </IonAccordion>
            <IonAccordion value="third">
            <IonItem slot="header" color="light">
                    <IonThumbnail>
                        <img alt="..." src="https://tipsparatuviaje.com/wp-content/uploads/2017/07/3.-Chilpachole-de-jaiba.jpg" />
                    </IonThumbnail>
                    <IonLabel class="padleft7px">
                        <h4>Jaiba y mariscos</h4>
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <p>Los veracruzanos llaman chilpachole a un asopado preparado con chile de chilpaya, un fruto redondo originario del centro del estado, parecido al chile piquín.</p>
                    <IonList className='Incluye'>
                        <h3>Ingredientes</h3>
                        <IonItem>
                            <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://www.gob.mx/cms/uploads/article/main_image/64489/burritos_de_machaca_jaiba_ingred.jpg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Jaibas</IonLabel>
                        </IonItem>

                        <IonItem>
                        <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://www.mexicodesconocido.com.mx/wp-content/uploads/2018/03/chile-de-arbol.jpg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Chile de árbol</IonLabel>
                        </IonItem>

                        <IonItem>
                        <IonThumbnail>
                                <img id="bordercirc" alt="..." src="https://www.gob.mx/cms/uploads/article/main_image/18127/Epazote.jpg" />
                            </IonThumbnail>
                            <IonLabel class="padleft7px">Epazote</IonLabel>
                        </IonItem>
                    </IonList>
                </div>
            </IonAccordion>
        </IonAccordionGroup>
        <div>
        <Swiper autoplay={true} slidesPerView={4} spaceBetween={10} freeMode={true} pagination={{ clickable: true}} modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]} className="">
            <IonContent>
            <SwiperSlide> 
                <IonCard id='cardDrSimi' color="light" className='he90' onClick={() => setPopoverOpen(true)}>
                    <img height={100} className="CardRes" alt={"CONCANACO SERVYTUR"} src={"https://parsers.vc/logo/4953c82d-8a63-48a9-9873-f3326668c77c-1.jpg"} style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                </IonCard>
                <IonPopover
                    isOpen={popoverOpen}  // Control visibility
                    onDidDismiss={() => setPopoverOpen(false)}  // Close popover when dismissed
                    style={{ '--width': '80%' }}
                    >
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h2>CONCANACO SERVYTUR MÉXICO, es una institución de interés público, autónoma y con personalidad jurídica y patrimonio propio.</h2>
                        <IonButton onClick={openHtmlPage}>concanaco.com.mx</IonButton>
                    </div>
                </IonPopover>
            </SwiperSlide>
            </IonContent>
            <IonContent>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://www.gob.mx/cms/uploads/action_program/main_image/28692/post_gobmx_acciones_bg_01.jpg"} />
                </IonCard>
            </SwiperSlide>
            </IonContent>
            <IonContent>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://materialesvaldez.mx/assets/img/pago/fonacot.jpeg"} />
                </IonCard>
            </SwiperSlide>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://upload.wikimedia.org/wikipedia/commons/b/ba/IMSS_Logos%C3%ADmbolo.png"} />
                </IonCard>
            </SwiperSlide>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://www.montepiedad.com.mx/storage/2023/01/logo.jpg"} />
                </IonCard>
            </SwiperSlide>
            <SwiperSlide>
                <IonCard id='cardFinabien' color="light" className='he90'>
                    <img height={100} className="CardRes" alt={"Financiera del Bienestar"} src={"https://upload.wikimedia.org/wikipedia/commons/2/2e/Logo_de_la_Loteria_Nacional_%28sin_texto%29.svg"} />
                </IonCard>
            </SwiperSlide>
            </IonContent>
        </Swiper>
        </div>
        <IonList className='Incluye padd50'>
            <h3>Informes</h3>
            <IonItem>
              <IonIcon icon={alarmSharp} slot="start"></IonIcon>
              <IonLabel>{marker.horario}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={linkSharp} slot="start"></IonIcon>
              <IonLabel>{marker.sitio_web}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={medalSharp} slot="start"></IonIcon>
              <IonLabel>{marker.tipo}</IonLabel>
            </IonItem>
        </IonList>


        </IonContent>
        </>
    );
};

export default RestaurantModal;