import React from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';
import './ReviewsModal.css';

interface Review {
  title: string;
  author: string;
  text: string;
  rating: number;
}

interface ReviewsModalProps {
  isOpen: boolean;
  onClose: () => void;
  reviews: Review[];
}

const ReviewsModal: React.FC<ReviewsModalProps> = ({ isOpen, onClose, reviews }) => {
  const renderStars = (rating: number) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span key={index} className="star">
        {index < rating ? '★' : '☆'}
      </span>
    ));
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Comentarios</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="reviews-modal-content">
        <IonList>
          {reviews.map((review, index) => (
            <IonItem key={index} className="review-card">
              <IonLabel>
                <h2 className="review-card-title">{review.title}</h2>
                <p className="review-card-author">by {review.author}</p>
                <p className="review-card-text">{review.text}</p>
                <div className="review-card-rating">
                  {renderStars(review.rating)}
                </div>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ReviewsModal;
