import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { changeLanguage } from 'i18next';

import './selectLanguage.css'


const SelectLang = ({iconColorClass}:{iconColorClass: string}) => {
    const [ t , i18n ] = useTranslation("global");

    return (
        <IonSelect
        aria-label="language"
        interface='popover'
        onIonChange={(e) => {changeLanguage(e.detail.value); console.log("Value:",e.detail.value)}}
        className={`${iconColorClass}`}
        justify='end'
        selectedText={`${t("info.flag")}`}
    >
        <IonSelectOption value="es">
            🇲🇽&nbsp;&nbsp;&nbsp;&nbsp;Español
        </IonSelectOption>
        <IonSelectOption value="maya">
            🇲🇽&nbsp;&nbsp;&nbsp;&nbsp;Maya
        </IonSelectOption>
        <IonSelectOption value="en">
            🇺🇸&nbsp;&nbsp;&nbsp;&nbsp;English
        </IonSelectOption>
        <IonSelectOption value="fr">
            🇫🇷&nbsp;&nbsp;&nbsp;&nbsp;Français
        </IonSelectOption>
        <IonSelectOption value="zh">
            🇨🇳&nbsp;&nbsp;&nbsp;&nbsp;汉语
        </IonSelectOption>
    </IonSelect>
    );
  };


export default SelectLang;
