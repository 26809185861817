import {  IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import React from 'react';
import { Redirect, Route} from 'react-router-dom';
import Tab1 from '../pages/Tab1';
//import Tab2 from '../pages/Tab2';
import Tab3 from '../pages/Tab3';
import Tab4 from '../pages/Tab4';
import { home, personCircle, trophy, homeOutline, ribbonOutline, personOutline } from 'ionicons/icons';
import "./Tabs.css"
import { useTranslation } from 'react-i18next';

const Tabs: React.FC = () => {
    const [ t ] = useTranslation("global");

    return (
        <IonTabs>
            <IonRouterOutlet>
            <Route path="/app/tabs/tab1" component={Tab1}/>
            {/*<Route path="/app/tabs/tab2" component={Tab2}/>*/}
            <Route path="/app/tabs/tab3" component={Tab3}/>
            <Route exact path="/app/tabs">
                <Redirect to="/app/tabs/tab1"/>
            </Route>
            <Route path="/app/tabs/tab4" component={Tab4}/>
            </IonRouterOutlet>
                <IonTabBar slot="bottom" className='degradado paddingt_7'>
                    <IonTabButton tab="tab1" href="/app/tabs/tab1" className='acomodo-tab'>
                            <IonIcon aria-hidden="true" color='light' size='medium' icon={homeOutline}/>
                            <IonLabel color={'light'}>{t("TabsMenu.home")}</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab3" href="/app/tabs/tab3" className='acomodo-tab'>
                            <IonIcon aria-hidden="true" color='light' size='medium' icon={ribbonOutline}/>
                            <IonLabel color={'light'}>{t("TabsMenu.trophy")}</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="tab4" href="/app/tabs/tab4" className='acomodo-tab'>
                            <IonIcon aria-hidden="true" color='light' size='medium' icon={personOutline}/>
                            <IonLabel color={'light'}>{t("TabsMenu.profile")}</IonLabel>
                    </IonTabButton>
                </IonTabBar>
        </IonTabs>
    );
};

export default Tabs;