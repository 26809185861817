import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonTitle, IonToolbar, IonicSlides } from '@ionic/react';

import location from '../assets/location-pin.svg'
import './CardTurCom.css'
import { TurComProps } from '../../src/interfaces';
import { arrowBack, callSharp, cellularSharp, desktopSharp, linkSharp, logoFacebook, logoInstagram, logoPinterest, logoSnapchat, logoTiktok, logoTwitter, logoWhatsapp, logoYoutube, mailSharp, mapSharp } from 'ionicons/icons';
import closePage from '../assets/close-circle.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';

function CardTurCom({nombre, empresa, localidad, estado, titulo, programa, latitud, longitud, heroImage, sitioWeb, descripcion, actividades, imagenes, datos_contacto, incluye} : TurComProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  console.log("CardTurCom");

  let contact0=false, contact1=false, contact2=false, contact3=false, contact4=false, contact5=false, contact6=false, contact7=false, contact8=false, contact9=false, contact10=false, contact11=false, contact12=false, contact13=false;

  if(datos_contacto[0].trim().length)
    contact0 = true;
  if(datos_contacto[1].trim().length)
    contact1 = true;
  if(datos_contacto[2].trim().length)
    contact2 = true;
  if(datos_contacto[3].trim().length)
    contact3 = true;
  if(datos_contacto[4].trim().length)
    contact4 = true;
  if(datos_contacto[5].trim().length)
    contact5 = true;
  if(datos_contacto[6].trim().length)
    contact6 = true;
  if(datos_contacto[7].trim().length)
    contact7 = true;
  if(datos_contacto[8].trim().length)
    contact8 = true;
  if(datos_contacto[9].trim().length)
    contact9 = true;
  if(datos_contacto[10].trim().length)
    contact10 = true;
  if(datos_contacto[11].trim().length)
    contact11 = true;
  if(datos_contacto[12].trim().length)
    contact12 = true;
  if(datos_contacto[13].trim().length)
    contact13 = true;
  
  return (
    <>
      <IonCard id='cardArt' color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={titulo} src={heroImage} />
        <IonCardHeader>
          <IonCardTitle>{nombre}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
            <div className="score">
              {localidad},{estado}
            </div>
            <div className="open">
              <p>{empresa}</p>
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='art-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-tour'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-tour' onClick={() => setIsOpen1(false)}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
            <IonImg className='header-modal-img-tour'
              src={heroImage}
              alt={titulo}
            ></IonImg>
            <IonTitle>
                <div className="title">
                    <div className="precio">
                        <div className="cantidad">
                        {nombre}
                        </div>
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
        <h3>{titulo}</h3>
        <IonList>
            <IonItem>
            <p>
                {descripcion}
            </p>
            </IonItem>
        </IonList>
              <Swiper
                // spaceBetween={5}
                modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
                autoplay={true}
                keyboard={true}
                pagination={{
                  dynamicBullets: true,
                }}
                scrollbar={true}
                zoom={true}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {imagenes.map((element, index) => 
            <>
              <SwiperSlide>
                <IonCard id='swiper-ioncard'>
                <img className="img-swiper" alt='Hola' src={element} />
                  <IonCardHeader>
                    <IonCardTitle>{actividades[index]}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </SwiperSlide>
                </>
              )
              }
              </Swiper>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Incluye </IonLabel>
          </IonListHeader>
          {
            incluye.map(element =>
              <>
                <IonItem>
                  <IonLabel>{element}</IonLabel>
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Informes </IonLabel>
          </IonListHeader>
            {
                contact0 && 
                <IonItem>
                    <IonIcon icon={desktopSharp} slot="start"></IonIcon>
                    <IonLabel>Operado por: {datos_contacto[0]}</IonLabel>
                </IonItem>
            }
            {
                contact1 && 
                <IonItem>
                    <IonIcon icon={mapSharp} slot="start"></IonIcon>
                    <p>{datos_contacto[1]}</p>
                </IonItem>
            }
            {
                contact2 && 
                <IonItem>
                    <IonIcon icon={callSharp} slot="start"></IonIcon>
                    <IonLabel>{datos_contacto[2]}</IonLabel>
                </IonItem>
            }
            {
                contact3 && 
                <IonItem>
                    <IonIcon icon={callSharp} slot="start"></IonIcon>
                    <a href={`tel:${datos_contacto[3]}`}></a>
                    <IonLabel>Llámanos</IonLabel>
                </IonItem>
            }
            {
                contact4 && 
                <IonItem>
                    <IonIcon icon={cellularSharp} slot="start"></IonIcon>
                    <IonLabel>{datos_contacto[4]}</IonLabel>
                </IonItem>
            }            
            {
                contact5 && 
                <IonItem>
                    <IonIcon icon={logoFacebook} slot="start"></IonIcon>
                    <a href={datos_contacto[5]} target="_blank" rel="noopener noreferrer">
                    <IonLabel>Contáctanos por Facebook</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact6 && 
                <IonItem>
                    <IonIcon icon={logoTwitter} slot="start"></IonIcon>
                    <a href={datos_contacto[6]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Twitter/X</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact7 && 
                <IonItem>
                    <IonIcon icon={logoInstagram} slot="start"></IonIcon>
                    <a href={datos_contacto[7]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Instagram</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact8 && 
                <IonItem>
                    <IonIcon icon={logoPinterest} slot="start"></IonIcon>
                    <a href={datos_contacto[8]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Pinterest</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact9 && 
                <IonItem>
                    <IonIcon icon={logoTiktok} slot="start"></IonIcon>
                    <a href={datos_contacto[9]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Tiktok</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact10 && 
                <IonItem>
                    <IonIcon icon={logoSnapchat} slot="start"></IonIcon>
                    <a href={datos_contacto[10]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Snapchat</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact11 && 
                <IonItem>
                    <IonIcon icon={logoYoutube} slot="start"></IonIcon>
                    <a href={datos_contacto[11]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Youtube</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact12 && 
                <IonItem>
                    <IonIcon icon={logoWhatsapp} slot="start"></IonIcon>
                    <a href={datos_contacto[12]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por WhatsApp</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact13 && 
                <IonItem>
                    <IonIcon icon={mailSharp} slot="start"></IonIcon>
                    <a href={`mailto:${datos_contacto[13]}`}>
                        <IonLabel>Mándanos un correo electrónico</IonLabel>
                    </a>
                </IonItem>
            }
        </IonList>


        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default CardTurCom;
