import React from 'react';
import { IonContent, IonPage, IonSearchbar } from '@ionic/react';
import './Slide1_bis.css'

function Slide1({urlImage, title} : {urlImage: string, title: string}) {
  return (
    <>
      <IonContent id='Slide1'>
          <div className="content">
              <video autoPlay controls src='../assets/Video/BaileFolklorico.mp4'>
                <source src='src/assets/Video/BaileFolklorico.mp4' type="video/mp4"/>
              </video>
              <div className="leyenda">
                  {title}   
                  J   
              </div>
            {/* <img src="'https://foodandtravel.mx/wp-content/uploads/2020/11/Cocina-mexicana-conferencia.jpg'" alt="" /> */}
          </div>
      </IonContent>
    </>
  );
}
export default Slide1;