import React, { useRef, useState } from 'react';
import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import '../components/PromotionCard.css'
import qrCode from '../assets/qr-code.svg'
import { arrowBack } from 'ionicons/icons';
import { Console } from 'console';
import {useTranslation} from 'react-i18next'
import Traducir from './Traducir';



function PromotionCard({nombre, imageUrl, promotion, tipo} : {nombre: string, imageUrl: string, promotion:string, tipo:string}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isCupon, setIsCupon] = useState(false);
    const [t,i18n] = useTranslation("global");
    const modal = useRef<HTMLIonModalElement>(null);

  return (
    <>
        <IonCard color="light" onClick={() => setIsOpen(true)}>
        <img alt={nombre} src={imageUrl} />
        <IonCardHeader>

            <IonCardTitle className='promotion-service'>{nombre}</IonCardTitle>
            <IonCardSubtitle className='promotion-type'>{tipo}</IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
            <p>{promotion}</p>
        </IonCardContent>
        </IonCard>

        <IonModal isOpen={isOpen}>
            <IonHeader>
                <IonToolbar className='name-header-modal1'>
                    <IonButtons slot='start'>
                        <IonButton onClick={() => setIsOpen(false)} color='light'>
                            <IonIcon icon={arrowBack}/>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{nombre}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding'>

                <img className='modal-img' alt={nombre} src={imageUrl} />
                <IonCardSubtitle className='validation'>{t("Tab3.titlePromo")}</IonCardSubtitle>
                <p className='promotion'>{promotion}</p>
                <div className="button-promotion">
                    <IonButton  id="open-modal" fill="outline" onClick={() => setIsCupon(true)}>
                        <img src={qrCode} alt='QR-code'></img> 
                        {t("Tab3.textQR")}
                        <>
                            {
                                console.log("enviar")
                            }
                        </>
                    </IonButton>
                </div>
                <div className="details">
                    <IonCardSubtitle>{t("Tab3.details")}</IonCardSubtitle>
                    <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus quos
                    reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum qui.
                    Eaque, dicta.
                    </p>
                </div>

                {/* MODAL QR --- START*/}

                <IonModal id="example-modal" ref={modal} trigger="open-modal" isOpen={isCupon}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle class='Codigo-promotion'>M56-6ED-6SA</IonTitle>
                        </IonToolbar>
                        <h1 className='Codigo-title'>{promotion}</h1>
                        <div className="qr-code">
                            <img src='https://upload.wikimedia.org/wikipedia/commons/d/d7/Commons_QR_code.png' alt='QR-code'></img>
                        </div>
                        <p>{t("Tab3.promoExample")}</p>

                    </IonContent>
                </IonModal>

                {/* MODAL QR --- END*/}
            </IonContent>

           
        </IonModal>
    </>
  );
//   <script src="https://unpkg.com/ionicons@4.5.10-0/dist/ionicons.js"></script>
}

export default PromotionCard;
