import React, { useRef, useState } from 'react';
import { IonAvatar, IonContent, IonModal, IonItem, IonLabel } from '@ionic/react';
import '../components/foodReceipts.css'

function FoodReceipts( {element, image, status, descripcion} : {element: string, image: string, status:boolean, descripcion:string}) {
  const [isOpen, setIsOpen] = useState(false);
  // const select = document.querySelector('#margin-active');

  // if(status == true){
  //   console.log('El ingrediente endémico es: ', element)
  //   select?.classList.add("active")
  // }

  // status ?  : select?.classList.toggle('no-endemic')

  return(  
    <>
      {/* <div className="margin-image" id='margin-active'> */}
      <div className={`margin-image ${status ? 'active': ''}`} id='margin-active'>
        <div className="image-container " >
            <img alt={element} src={image} className='circular-border ' onClick={() => setIsOpen(true)}/> 
        </div>
        {/* <IonAvatar slot="start" className='circular-square' onClick={() => setIsOpen(true)}>
        </IonAvatar> */}
      </div>

      <IonModal className='Modal-ingredientes' id='ion-modal-info' isOpen={isOpen} initialBreakpoint={0.5} breakpoints={[0, 0.25, 0.5, 0.75]} handleBehavior="cycle">
        <IonContent className="ion-padding">
          <div className=".ion-justify-content-between ion-padding-bottom">
            {/* <h2>
              Ingrediente de Origen
            </h2> */}
            {/* <IonAvatar slot="start" className='circular-square' id="open-modal">
              <img alt={element} src={image} />
            </IonAvatar> */}
            <div className="details">
              <div className="image">
                <IonAvatar slot="start" className='circular-square' id="open-modal">
                  <img alt="..." src={image} />
                </IonAvatar>
              </div>
              <div className="info">
                <IonLabel>{element}</IonLabel>
                <div className="contenido">
                  {
                    Endemico(status, descripcion)
                  }
                </div>
              </div>

            </div>
              
              
              
    
          </div>
          
        </IonContent>

      </IonModal>
      
    
    </>
  );

}

function Endemico (status:boolean, descripcion:string) {
  const select = document.querySelector('#margin-active');

  //const endemic = status ? descripcion : ""
  const endemic = descripcion
  // status ? select?.classList.toggle('active') : ""



  return <p className='description'>{endemic}</p>
}
export default FoodReceipts;



