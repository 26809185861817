import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import React from 'react';

import "./Register.css"

const Register: React.FC = () => {
    const router = useIonRouter();

    const doRegister = (event: any) =>{
        event.preventDefault();
        console.log('doRegister');
        router.goBack();
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className='Color-degradado'>
                    <IonButtons slot="start">
                        <IonBackButton color={'light'} defaultHref="/"></IonBackButton>
                    </IonButtons>
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <IonTitle className='title ion-margin-bottom ion-margin-top ion-text-center'>Registro</IonTitle>
            <form onSubmit={doRegister}>
                    <div className='margenH'>    
                        <IonInput
                            aria-label="Email"
                            placeholder="Email"
                            fill="outline"
                            labelPlacement='floating'
                            label="Dirección de correo electrónico"
                            type='email'
                            className='Inputs-login'
                        ></IonInput>
                    </div>
                    <div className='margenH'>  
                        <IonInput
                            aria-label="Contraseña"
                            placeholder="Contraseña"
                            fill="outline"
                            labelPlacement='floating'
                            label='Contraseña'
                            type='password'
                            className='ion-margin-top ion-margin-bottom Inputs-login'
                        ></IonInput>
                    </div>
                    <br></br>
                    <IonButton 
                        expand='block'
                        shape='round'
                        size='default'
                        className='botoncustom ion-text-capitalize'
                        type='submit'>
                            Registrar
                    </IonButton>
            </form>
            </IonContent>
        </IonPage>
    );
};

export default Register;