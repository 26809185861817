import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';

const TurismoComuni: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>My Direct Component</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <h1>Este es un componente invocado por URL directa</h1>
        <p>Puedes acceder a este componente desde la URL: `/my-component`.</p>
      </IonContent>
    </IonPage>
  );
};

export default TurismoComuni;
