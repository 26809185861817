import { IonCard, IonCardHeader, IonCardTitle, IonListHeader, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonTitle,  IonToolbar, IonicSlides, IonText, IonPage } from '@ionic/react';
import React, {  useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { alarmSharp, callSharp, cellularSharp, desktopSharp, linkSharp, logoFacebook, logoInstagram, logoPinterest, logoSnapchat, logoTiktok, logoTwitter, logoWhatsapp, logoYoutube, mailSharp, mapSharp, medalSharp,} from 'ionicons/icons';
import { TurComProps } from '../interfaces';

// import required modules

import closePage from '../assets/close-circle.svg'
import location_white from '../assets/location-pin.svg'


//Estilos de cada carta
import './TurComModal.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

import dataTurComEsp from '../data/maya-ka-an.json'
import dataTurComMaya from '../data/maya-ka-an-lang-maya.json'
import dataTurComEng from '../data/maya-ka-an-lang-english.json'
import dataTurComFren from '../data/maya-ka-an-lang-french.json'
import dataTurComChin from '../data/maya-ka-an-lang-chinese.json'


// Props interface for ItemDetail
interface TurComDetailProps {
    itemId: string;
}
  
  const TurComQuery: React.FC<TurComDetailProps> = ({ itemId }) => {
    const [marker, setItem] = useState<TurComProps | null>(null);

    const [t,i18n] = useTranslation("global");

    console.log('Language: ',i18n.language);
  
    const langEnable = i18n.language;
  
    let dataTurCom = dataTurComMaya;
  
    switch(langEnable){
        case 'es':
          dataTurCom = dataTurComEsp;
            break;
        case 'en':
          dataTurCom = dataTurComEng;
            break;
        case 'fr':
          dataTurCom = dataTurComFren;
            break;
        case 'zh':
          dataTurCom = dataTurComChin;
            break;
        case 'maya':
          dataTurCom = dataTurComMaya;
            break;
    }

    useEffect(() => {
            const foundItem = dataTurCom.find((marker: TurComProps) => marker.id === itemId);
            setItem(foundItem || null);
      }, [itemId]);
    
      if (!marker) {
        return <IonText>Resource not found.</IonText>;
      }

    let contact0=false, contact1=false, contact2=false, contact3=false, contact4=false, contact5=false, contact6=false, contact7=false, contact8=false, contact9=false, contact10=false, contact11=false, contact12=false, contact13=false;

    if(marker.datos_contacto[0].trim().length)
      contact0 = true;
    if(marker.datos_contacto[1].trim().length)
      contact1 = true;
    if(marker.datos_contacto[2].trim().length)
      contact2 = true;
    if(marker.datos_contacto[3].trim().length)
      contact3 = true;
    if(marker.datos_contacto[4].trim().length)
      contact4 = true;
    if(marker.datos_contacto[5].trim().length)
      contact5 = true;
    if(marker.datos_contacto[6].trim().length)
      contact6 = true;
    if(marker.datos_contacto[7].trim().length)
      contact7 = true;
    if(marker.datos_contacto[8].trim().length)
      contact8 = true;
    if(marker.datos_contacto[9].trim().length)
      contact9 = true;
    if(marker.datos_contacto[10].trim().length)
      contact10 = true;
    if(marker.datos_contacto[11].trim().length)
      contact11 = true;
    if(marker.datos_contacto[12].trim().length)
      contact12 = true;
    if(marker.datos_contacto[13].trim().length)
      contact13 = true;
  
      function onDismiss(): void {
          throw new Error('Function not implemented.');
      }

    return (
        <IonPage>
            <IonHeader>
            <IonToolbar className='custom'>
                <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
            </IonToolbar>
            <IonContent>
                <IonImg className='header-modal-img-tour'
                    src={marker.heroImage}
                    alt={marker.titulo}
                >                    
                </IonImg>

                <div className="marquee">
                <div className="track">
                    {/* <div className="content">&nbsp;Dr Simi: Lo mismo, pero más barato - FONACOT: Siempre a favor de los trabajadores - IMSS: Seguridad y Solidaridad Social - FINABIEN: Servicios Financieros&nbsp;</div> */}
                    <div className="content">&nbsp;CONCANACO, Confederación de Cámaras Nacionales de Comercio, Servicios y Turismo. La CONCANACO SERVYTUR es el ÚNICO Organismo del sector COMERCIO, SERVICIOS y TURISMO. La aparición de mensajes se puede programar.&nbsp;</div>
                </div>
                </div>
                <IonTitle>
                    <div className="titleturcom">
                        <div className="precio">
                            {marker.nombre}<br/>
                        </div>
                    </div>
                    <div className="titleturcom">
                        <div className="precio">
                            {marker.localidad}, {marker.estado}
                        </div>
                    </div>
                    <div className="titleturcom">
                        <div className="precio">
                            {marker.empresa}
                        </div>
                    </div>
                </IonTitle>
            </IonContent>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
            <h3>{marker.titulo}</h3>
            <IonList>
                <IonItem>
                <p>{marker.descripcion}</p>
                </IonItem>
            </IonList>
            <Swiper
                // spaceBetween={5}
                modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
                autoplay={true}
                keyboard={true}
                pagination={{
                  dynamicBullets: true,
                }}
                scrollbar={true}
                zoom={true}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {marker.imagenes.map((element, index) => 
            <>
              <SwiperSlide>
                <IonCard id='swiper-ioncard'>
                <img className="img-swiper" alt='Hola' src={element} />
                  <IonCardHeader>
                    <IonCardTitle>{marker.actividades[index]}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </SwiperSlide>
                </>
              )
              }
              </Swiper>

        <IonList className='Incluye padd-incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Incluye</IonLabel>
          </IonListHeader>
          {
            marker.incluye.map(element =>
              <>
                <IonItem className="small-spacing">
                  <IonLabel>{element}</IonLabel>
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Informes </IonLabel>
          </IonListHeader>
            {
                contact0 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={desktopSharp} slot="start"></IonIcon>
                    <IonLabel>Operado por: {marker.datos_contacto[0]}</IonLabel>
                </IonItem>
            }
            {
                contact1 && 
                <IonItem>
                    <IonIcon icon={mapSharp} slot="start"></IonIcon>
                    <p>{marker.datos_contacto[1]}</p>
                </IonItem>
            }
            {
                contact2 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={callSharp} slot="start"></IonIcon>
                    <a href={`tel:${marker.datos_contacto[2]}`}>
                        <IonLabel>Llámanos con un click</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact3 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={callSharp} slot="start"></IonIcon>
                    <a href={`tel:${marker.datos_contacto[3]}`}>
                        <IonLabel>Llámanos con un click</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact4 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={cellularSharp} slot="start"></IonIcon>
                    <a href={`tel:${marker.datos_contacto[4]}`}>
                        <IonLabel>{marker.datos_contacto[4]}</IonLabel>
                    </a>
                </IonItem>
            }            
            {
                contact5 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoFacebook} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[5]} target="_blank" rel="noopener noreferrer">
                    <IonLabel>Contáctanos por Facebook</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact6 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoTwitter} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[6]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Twitter/X</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact7 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoInstagram} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[7]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Instagram</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact8 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoPinterest} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[8]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Pinterest</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact9 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoTiktok} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[9]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Tiktok</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact10 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoSnapchat} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[10]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Snapchat</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact11 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoYoutube} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[11]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por Youtube</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact12 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={logoWhatsapp} slot="start"></IonIcon>
                    <a href={marker.datos_contacto[12]} target="_blank" rel="noopener noreferrer">
                        <IonLabel>Contáctanos por WhatsApp</IonLabel>
                    </a>
                </IonItem>
            }
            {
                contact13 && 
                <IonItem className="small-spacing">
                    <IonIcon icon={mailSharp} slot="start"></IonIcon>
                    <a href={`mailto:${marker.datos_contacto[13]}`}>
                        <IonLabel>Mándanos un correo electrónico</IonLabel>
                    </a>
                </IonItem>
            }
        </IonList>


        </IonContent>
        </IonPage>
    );
};

export default TurComQuery;