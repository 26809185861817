import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonTitle,  IonToolbar } from '@ionic/react';
import React, {  useState } from 'react';
import { IonIcon } from '@ionic/react';
import { alarmSharp, linkSharp, medalSharp,} from 'ionicons/icons';
import { MuseoProps } from '../interfaces';

// import required modules

import closePage from '../assets/close-circle.svg'
import location_white from '../assets/location-pin.svg'


//Estilos de cada carta
import './CardMuseo.css'


const CustomSitesModal = ({
    marker,
    onDismiss,
  }: {
    marker : MuseoProps;
    onDismiss: () => void;
  }) => {
    return (
        <>
           <IonHeader className='museo-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-museo'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-museo' onClick={() => onDismiss()}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
              <div className="image-potada">
                <img className='header-modal-img-museo'
                  src={(marker.urlImagen[0])}
                  alt={marker.nombre}
                />

              </div>
            <IonTitle>
            <div className="title">
                    <div className="localidad">
                        <div className="icon-loc">
                            <IonImg
                                src={location_white}            
                                alt="Location_white"
                            ></IonImg>
                        </div>
                        <div className="lugar">
                            {marker.municipio}
                        </div>
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        <div className="content-museo">
            <div className="title-name">
                <h1>{marker.nombre}</h1>
            </div>
        </div>
        <p>
            {marker.descripcion}
        </p>

        <IonList className='Incluye'>
            <h3>Reports</h3>
            <IonItem>
              <IonIcon icon={alarmSharp} slot="start"></IonIcon>
              <IonLabel>{marker.horario}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={linkSharp} slot="start"></IonIcon>
              <IonLabel>{marker.sitio_web}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={medalSharp} slot="start"></IonIcon>
              <IonLabel>{marker.promocion}</IonLabel>
            </IonItem>
        </IonList>


        </IonContent>
        </>
    );
};

export default CustomSitesModal;