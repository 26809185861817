import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonTitle, IonToolbar, IonicSlides } from '@ionic/react';

import location from '../assets/location-pin.svg'
import './CardTour.css'
import { ToursProps } from '../../src/interfaces';
import { arrowBack, callSharp, linkSharp } from 'ionicons/icons';
import closePage from '../assets/close-circle.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/controller'
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import '@ionic/react/css/ionic-swiper.css';

function CardTour({estacion, titulo, imgPortada, precio, sitioWeb, actividades, imgActividades, telefono, incluye} : ToursProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  
  return (
    <>
      <IonCard id='cardArt' color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={titulo} src={imgPortada} />
        <IonCardHeader>
          <IonCardTitle>{titulo}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
            <div className="score">
              ${precio}.00 MXN
            </div>
            <div className="open">
              <p>{telefono}</p>
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='art-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-tour'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-tour' onClick={() => setIsOpen1(false)}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
            <IonImg className='header-modal-img-tour'
              src={imgPortada}
              alt={titulo}
            ></IonImg>
            <IonTitle>
                <div className="title">
                    <div className="precio">
                         
                        <div className="cantidad">
                        desde ${precio}.00
                        </div>
                        <div className="moneda">
                            Mxn
                        </div>
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
        <h1>{titulo}</h1>
              <Swiper
                // spaceBetween={5}
                modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
                autoplay={true}
                keyboard={true}
                pagination={{
                  dynamicBullets: true,
                }}
                scrollbar={true}
                zoom={true}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
              {imgActividades.map((element, index) => 
            <>
              <SwiperSlide>
                <IonCard id='swiper-ioncard'>
                <img className="img-swiper" alt='Hola' src={element} />
                  <IonCardHeader>
                    <IonCardTitle>{actividades[index]}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </SwiperSlide>
                </>
              )
              }
              </Swiper>

        <IonList className='Incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Incluye </IonLabel>
          </IonListHeader>
          {
            incluye.map(element =>
              <>
                <IonItem>
                  <IonLabel>{element}</IonLabel>
                </IonItem>
              </>  
            )
          }
          
        </IonList>

        <IonList className='Incluye'>
          <IonListHeader>
            <IonLabel className='title-incluye'>Informes </IonLabel>
          </IonListHeader>
            <IonItem>
              <IonIcon icon={callSharp} slot="start"></IonIcon>
              <IonLabel>{telefono}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={linkSharp} slot="start"></IonIcon>
              <IonLabel>{sitioWeb}</IonLabel>
            </IonItem>
        </IonList>


        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default CardTour;
