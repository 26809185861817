import React from 'react';
import { IonImg } from '@ionic/react';
import './starRating.css'
// import {AiOutlineStar, AiFillStar} from 'react-icons/ai'
import PropTypes from 'prop-types';
import starOutLine from '../../assets/star-outline.svg'
import starFilled from '../../assets/star-filled.svg'

const Estrellas = (props: {score:number}) => {
  return (
    <div className='icons-star'>
      {
        [... new Array(5)].map((star, index)=>{
          return (
            (index<props.score) ? <IonImg key={index} class='starimg' src={starFilled} alt="The Wisconsin"></IonImg> :<IonImg key={index}className='starimg' src={starOutLine} alt="The Wisconsin"></IonImg>)
        })
      }
    </div>
  )
}

export default Estrellas;

Estrellas.propTypes = {
  score: PropTypes.number.isRequired,
};