import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './RestaurantCard.css'
import image from '../../assets/map-pin.svg'
import Star from './starRating'
import SearchPlatillo from '../SearchPlatillo'
// import dataPlatillo from '../../data/platillos.json'
import dataPlatillo from '../../data/dataPlatillos.json'
import FoodItems from '../foodItems';
import { RestaurantProps } from '../../interfaces';
import { arrowBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

function RestaurantCard({nombre, direccion, imageUrl, calificacion, open} : RestaurantProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  const [ t , i18n ] = useTranslation("global");
  return (
    <>
      <IonCard color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={nombre} src={imageUrl} />
        <IonCardHeader>
          <IonCardTitle className='title-restaurant-tab2'>{nombre}</IonCardTitle>
          <div className='container'>
              <div className="icon">
                <IonImg
                  src={image}            
                  alt="Location"
                ></IonImg>
                {/* <IonIcon icon="../assets/map-pin.svg" slot="end"></IonIcon> */}
              </div>
              <div className="ubicacion">
                <IonCardSubtitle>{direccion}</IonCardSubtitle>
              </div>
          </div>
        </IonCardHeader>

        <IonCardContent>
            <div className="score">
              <Star 
                score={calificacion}
              ></Star>
            </div>
            <div className="open title-restaurant-tab2">
              <p>{`${t("restaurant.open")} `} {open}</p>
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='restaurant-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal'>
              <IonButtons className='button-close'>
                <IonButton onClick={() => setIsOpen1(false)}>
                    <IonIcon icon={arrowBack}/>
                </IonButton>
              </IonButtons>
            <IonImg className='header-modal-img'
              src={imageUrl}
              alt={imageUrl}
            ></IonImg>
            <IonTitle>{t("restaurant.title")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
          {/* <SwitchFoodDrink></SwitchFoodDrink> */}

          <SearchPlatillo></SearchPlatillo>
          {/* <SwitchFoodDrink></SwitchFoodDrink> */}

          {dataPlatillo.map(platillo =>
            <FoodItems
              key = {platillo.nombre}
              nombre ={platillo.nombre}
              descripcion={platillo.descripcion}
              urlimagen={platillo.urlimagen}
              ingredientes={platillo.ingredientes}
              nombreIngrediente={platillo.ingredientes.map(name =>
                  name.nombre
                )
              }
              imageIngrediente={platillo.ingredientes.map(image =>
                image.image_pat
              )}
              estatusEndemico = {platillo.ingredientes.map(estatus =>
                estatus.endemico  
              )}
              descripcionEndemico={platillo.ingredientes.map(des => 
                des.descripcion
              )}
              // beneficios={platillo.beneficios}
            />
          )}

        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default RestaurantCard;
