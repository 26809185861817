import {  useIonModal } from '@ionic/react';
import React, { useState } from 'react';
import {Marker} from 'react-leaflet'
import { BancoProps } from '../interfaces';

import L from 'leaflet';
import CustomSitesModal from './CustomSitesModal';
import icon from 'leaflet/dist/images/marker-icon.png';

const DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 25], // size of the icon
});
L.Marker.prototype.options.icon = DefaultIcon;

const MarkersSitesB = ({sitios,Icon}:{sitios:BancoProps[], Icon: string}) => {
    const [selectedMarker, setSelectedMarker] = useState<BancoProps>();

    const customIcon = L.icon({
        iconUrl: Icon,
        iconSize: [25, 25] // size of the icon
    });

    const [present, dismiss] = useIonModal(CustomSitesModal,{
        marker: selectedMarker,
        onDismiss: () => dismiss()
      });

    const modalOptions = {
        onDidDismiss: ()=>dismiss()
    }

    const markers = sitios.map((item,i) =>(
        <Marker key={i} position={[Number(item.latitud),Number(item.longitud)]} icon={(Icon==="")?(DefaultIcon):(customIcon)}
        eventHandlers={{
            click: () => {
                setSelectedMarker(item);
                present(modalOptions);
            },
          }}>
        </Marker>
    ))
    return <>{markers}</>;
};

export default MarkersSitesB;