import React, { useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonModal, IonTitle, IonToolbar } from '@ionic/react';

import location from '../assets/location-pin.svg'
import './CardArtesanias.css'
import { ArtesaniasProps } from '../../src/interfaces';
import { arrowBack } from 'ionicons/icons';

import closePage from '../assets/close-circle.svg'

function CardArtesnia({nombreAutor, nombrePieza, localidad, descripcion, historia, proceso, precio, urlImg} : ArtesaniasProps) {
  const [isOpen1, setIsOpen1] = useState(false);
  
  return (
    <>
      <IonCard id='cardArt' color="light" onClick={() => setIsOpen1(true)} /*routerLink='/Menu'*/ >
        <img className="CardRes" alt={nombrePieza} src={urlImg} />
        <IonCardHeader>
          <IonCardTitle>{nombrePieza}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
            <div className="score">
              {localidad}
              {/* ${precio}.00 MXN */}
            </div>
            <div className="open">
              <p>{nombreAutor}</p>
            </div>
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={isOpen1} keepContentsMounted={true}>
        <IonHeader className='art-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-art'>
              <IonButtons className='button-close'>
                <IonButton className='text-close' onClick={() => setIsOpen1(false)}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
            <IonImg className='header-modal-img-art'
              src={urlImg}
              alt={nombrePieza}
            ></IonImg>
            <IonTitle>
                <div className="title">
                    <div className="localidad">
                    </div>
                    <div className="precio">
                        <div className="icon-loc">
                            <IonImg
                                src={location}            
                                alt="Location"
                            ></IonImg>
                        </div>
                        <div className="lugar">
                            {localidad}
                        </div>
                        {/* <div className="cantidad">
                            ${precio}.00
                        </div>
                        <div className="moneda">
                            Mxn
                        </div> */}
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        
        <h1>{nombrePieza}</h1>

        <p>
            {descripcion}
        </p>
        <p>
            {proceso}
        </p>

        <p>
            {historia}
        </p>
        
          


        </IonContent>
      </IonModal>
    
    
    </>

  );
}


export default CardArtesnia;
