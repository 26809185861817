import { IonButton } from '@ionic/react';
import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ContainerProps } from "../interfaces"

import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCreative,Navigation } from 'swiper';

import '@ionic/react/css/ionic-swiper.css';
import './Intro.css'
import Intro1Svg from '../assets/ImgIntro/Intro1.svg';
import Intro2Svg from '../assets/ImgIntro/Intro2.svg';
import Intro3Svg from '../assets/ImgIntro/Intro3.svg';
import Intro4Svg from '../assets/ImgIntro/Intro4.svg';
import Intro5Svg from '../assets/ImgIntro/Intro5.svg';

// importando slides de inicio
import Slide1 from './Slide1_bis';

const Intro: React.FC<ContainerProps> = ({onFinish}) => {

    return (
        <>
        <Swiper
        cssMode={true}
        grabCursor={true}
        navigation={true}
        mousewheel={true}
        keyboard={true}
        modules={[EffectCreative,Navigation]}
        className="SwiperFull"
        > 
            <SwiperSlide className='dispocicionSlider'>
                <>
                    <Slide1
                        title='Descubre México'
                        // urlImage='https://st3.depositphotos.com/26634596/34631/i/450/depositphotos_346318702-stock-photo-the-dolls-are-made-of.jpg'
                        urlImage='../assets/Video/BaileFolklorico.mp4'
                    ></Slide1>
                </>
                {/* <img id="imgintro" src={Intro1Svg} alt = "Intro 1" /> */}

            </SwiperSlide>
            <SwiperSlide className='dispocicionSlider'>
                <>
                    <Slide1
                        title='Los mejores platillos'
                        // urlImage='https://foodandtravel.mx/wp-content/uploads/2020/11/Cocina-mexicana-conferencia.jpg'
                        urlImage='../assets/Video/Mascaras.mp4'
                    ></Slide1>
                </>
                {/* <img id="imgintro" src={Intro2Svg} alt = "Intro 2" /> */}
            </SwiperSlide>
            <SwiperSlide className='dispocicionSlider'>
                <>
                    <Slide1
                        title='Los lugares emblemáticos'
                        // urlImage='https://www.mexicodestinos.com/blog/wp-content/uploads/2015/02/Paraisos-para-el-Ecoturismo-en-Mexico-2.jpg'
                        urlImage='../assets/Video/Playa.mp4'
                    ></Slide1>
                </>
                {/* <img id="imgintro" src={Intro3Svg} alt = "Intro 3" /> */}
            </SwiperSlide>
            <SwiperSlide className='dispocicionSlider'>
                <div className="img-swiper">
                    <img src={Intro5Svg} alt = "Intro 4" /> 
                </div>
                <div className="button-swiper">
                    <IonButton color="light" className='button-intro' onClick={()=>onFinish()}>Comenzar</IonButton>
                </div>
            </SwiperSlide>
        </Swiper>
        {/* <IonButton id="botonarriba" className='ion-margin-top' onClick={()=>onFinish()}>Finalizar</IonButton> */}
        </>
    );
};

export default Intro;