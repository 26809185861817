import { IonButton, IonButtons, IonContent, IonHeader, IonThumbnail, IonImg, IonItem, IonLabel, IonList, IonTitle,  IonToolbar } from '@ionic/react';
import React, {  useState } from 'react';
import { IonIcon } from '@ionic/react';
import { alarmSharp, linkSharp, medalSharp,} from 'ionicons/icons';
import { ZonaCoopProps } from '../interfaces';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';


// import required modules

import closePage from '../assets/close-circle.svg'
import location_white from '../assets/location-pin.svg'


//Estilos de cada carta
import './CardRestaurant.css'


const FinancieraModal = ({
    marker,
    onDismiss,
  }: {
    marker : ZonaCoopProps;
    onDismiss: () => void;
  }) => {
    return (
        <>
           <IonHeader className='museo-ion-header'>
          <IonToolbar className='name-header-modal1 toolbar-modal-museo'>
              <IonButtons className='button-close'>
                <IonButton className='text-close-museo' onClick={() => onDismiss()}>
                  <img src={closePage} alt="imageClose" id='icon-close' />
                </IonButton>
              </IonButtons>
              <div className="image-potada">
                <img className='header-modal-img-museo'
                  src={(marker.urlImagen[0])}
                  alt={marker.nombre}
                />

              </div>
            <IonTitle>
            <div className="title">
                    <div className="localidad">
                        <div className="icon-loc">
                            <IonImg
                                src={location_white}            
                                alt="Location_white"
                            ></IonImg>
                        </div>
                        <div className="lugar">
                            {marker.municipio}
                        </div>
                    </div>
                </div>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonContent fullscreen={true} className="ion-padding">
        <div className="content-museo">
            <div className="title-name">
                <h1>{marker.nombre}</h1>
            </div>
        </div>
        <p>
            {marker.descripcion}
        </p>

        <IonAccordionGroup>
            <h3>Principales Productores</h3>
            <IonAccordion value="first">
                <IonItem slot="header" color="light">
                    <IonThumbnail>
                        <img alt="..." src="https://pbs.twimg.com/media/FHAY_PwX0Aw9jBO.jpg" />
                    </IonThumbnail>
                    <IonLabel class="padleft7px">
                        <h4>Remesas Internacionales de Dinero</h4>
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <p>Giro Telegráfico Internacional: Recepción de dinero desde el extranjero para ser pagado en México en moneda nacional en efectivo, en cualquiera de las más de 1,700 sucursales telegráficas.</p>

                </div>
            </IonAccordion>
            <IonAccordion value="second">
            <IonItem slot="header" color="light">
                    <IonThumbnail>
                        <img alt="..." src="https://pbs.twimg.com/media/FHAY_PwX0Aw9jBO.jpg" />
                    </IonThumbnail>
                    <IonLabel class="padleft7px">
                        <h4>Remesas Internacionales de Dinero</h4>
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <p>Giro Telegráfico Internacional: Recepción de dinero desde el extranjero para ser pagado en México en moneda nacional en efectivo, en cualquiera de las más de 1,700 sucursales telegráficas.</p>

                </div>

            </IonAccordion>
            <IonAccordion value="third">
            <IonItem slot="header" color="light">
                    <IonThumbnail>
                        <img alt="..." src="https://pbs.twimg.com/media/FHAY_PwX0Aw9jBO.jpg" />
                    </IonThumbnail>
                    <IonLabel class="padleft7px">
                        <h4>Remesas Internacionales de Dinero</h4>
                    </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                    <p>Giro Telegráfico Internacional: Recepción de dinero desde el extranjero para ser pagado en México en moneda nacional en efectivo, en cualquiera de las más de 1,700 sucursales telegráficas.</p>

                </div>
            </IonAccordion>
        </IonAccordionGroup>

        <IonList className='Incluye'>
            <h3>Servicios</h3>
            <IonItem>
              <IonIcon icon={alarmSharp} slot="start"></IonIcon>
              <IonLabel>{marker.horario}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={linkSharp} slot="start"></IonIcon>
              <IonLabel>{marker.sitio_web}</IonLabel>
            </IonItem>

            <IonItem>
              <IonIcon icon={medalSharp} slot="start"></IonIcon>
              <IonLabel>{marker.tipo}</IonLabel>
            </IonItem>
        </IonList>



        </IonContent>
        </>
    );
};

export default FinancieraModal;