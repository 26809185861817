import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonLabel, IonNote, IonRow,  IonToolbar, IonPopover } from '@ionic/react';
import React, { useEffect } from 'react';
import { IonIcon } from '@ionic/react';
import { globeOutline, locationSharp, phonePortraitOutline } from 'ionicons/icons';
import { Marcador } from '../interfaces';
import './MarkerInfoWindow.css'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';

import mxproficon from '../assets/logo.svg';
import cooperativa from '../assets/cooperativa-circle.svg';
import comidatipica from '../assets/molcajete-circle.svg';
import arqueolo from '../assets/piramide-circle.svg';
import chac from '../assets/chac-circle.svg';


const MarkerInfoWindow = ({
    marker,
    onDismiss,
  }: {
    marker : Marcador;
    onDismiss: () => void;
  }) => {
    useEffect(()=>{
        console.log(`../assets/imgCorredor/${marker.image1}`);
    },[]);
    const re = new RegExp("https://");
    const [t,i18n] = useTranslation("global");
    return (
        <>
            <IonHeader className='ion-no-border'>
                <IonToolbar id='modalMarkers' >
                </IonToolbar>
                {marker.image1?(
                    marker.image1.match(re)?(<img src={marker.image1}/>):(<img src={`/assets/imgCorredor/${marker.image1}`}/>))
                :("")}
            </IonHeader>
            <IonContent>
                {marker.imagenes?(
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                    >
                        <SwiperSlide><img className='imgintro' src={`/assets/imgCorredor/${marker.imagenes[0].url}`}/></SwiperSlide>
                        <SwiperSlide><img className='imgintro' src={`/assets/imgCorredor/${marker.imagenes[1].url}`}/></SwiperSlide>
                        <SwiperSlide><img className='imgintro' src={`/assets/imgCorredor/${marker.imagenes[2].url}`}/></SwiperSlide>
                    </Swiper>
                    ):("")
                }
                <p></p>
                <IonGrid className='ion-padding'>
                    <IonRow className='padimagen'>
                        <IonCol size='12'>
                            <IonLabel>
                                <h1>{marker.nombreEstacion?(marker.nombreEstacion):(marker.nombrePolo)}</h1>
                                {marker.horarios?(<IonNote>{t("restaurant.open")} {marker.horarios}</IonNote>):("")}
                            </IonLabel>
                        </IonCol>
                    </IonRow>

                    <IonRow className='ion-margin-botton'>
                        <IonCol size='12' >
                            <IonLabel>
                                {marker.descripcion}
                            </IonLabel><br></br>
                            <IonRow content='center'>
                            <IonButton fill="outline" size="small" className="simple-icon" id="click-trig">
                                <img src={mxproficon} alt="Mas Información" id='icon-mxprof' />
                            </IonButton>
                            <IonButton fill="outline" size="small" className="simple-icon" id="click-coop">
                                <img src={cooperativa} alt="Mas Información" id='icon-mxprof'/>
                            </IonButton>
                            <IonButton fill="outline" size="small" className="simple-icon" id="click-comi">
                                <img src={comidatipica} alt="Mas Información" id='icon-mxprof' />
                            </IonButton>
                            <IonButton fill="outline" size="small" className="simple-icon" id="click-arq">
                                <img src={arqueolo} alt="Mas Información" id='icon-mxprof' />
                            </IonButton>
                            <IonButton fill="outline" size="small" className="simple-icon" id="click-mus">
                                <img src={chac} alt="Mas Información" id='icon-mxprof' />
                            </IonButton>
                            </IonRow>
                            <IonPopover trigger="click-trig" triggerAction="click">
                                <IonContent class="ion-padding">Sabías que: en Yucatán está el muelle más largo del mundo, y que la primera iglesia sobre tierra firme en América está en este mismo estado.</IonContent>
                            </IonPopover>
                            <IonPopover trigger="click-coop" triggerAction="click">
                                <IonContent class="ion-padding">Sabías que: Yucatán esta impulsando el crecimiento de pequeños productores a través de la creación de Cooperativas. Búscalas en el mapa y ayúdanos a impulsarlos.</IonContent>
                            </IonPopover>
                            <IonPopover trigger="click-comi" triggerAction="click">
                                <IonContent class="ion-padding">Sabías que: Yucatán es uno de los estados de México con mayor historia y desarrollo gastronómico. No puedes perderte sus comidas típicas. Localízanos en el mapa.</IonContent>
                            </IonPopover>
                            <IonPopover trigger="click-arq" triggerAction="click">
                                <IonContent class="ion-padding">Sabías que: en Yucatán se estableció una de las culturas más maravillosas y enigmáticas de todo el mundo. Y su legado esta al alcance de tus pies. Busca los sitios cercanos.</IonContent>
                            </IonPopover>
                            <IonPopover trigger="click-mus" triggerAction="click">
                                <IonContent class="ion-padding">Sabías que: Yucatán cuenta con inumerables museos que incluyen piezas históricas y modernas, además de exposiciones de artistas locales que te deslumbrarán.</IonContent>
                            </IonPopover>
                        </IonCol>
                    </IonRow>

                    <IonRow className='ion-justify-content-start ion-align-items-center'>
                        <IonCol size='1'>
                            <IonIcon icon={locationSharp} color="success" style={{fontSize: "1.5rem"}}/>
                        </IonCol>
                        <IonCol size='11'>{marker.direccion}</IonCol>
                    </IonRow>

                    <IonRow className='ion-justify-content-start ion-align-items-center'>
                        <IonCol size='1'>
                            <IonIcon icon={globeOutline} color="success" style={{fontSize: "1.5rem"}}/>
                        </IonCol>
                        <IonCol size='11'>{marker.sitioweb}</IonCol>
                    </IonRow>
                    
                    <IonRow className='ion-justify-content-start ion-align-items-center'>
                        <IonCol size='1'>
                            <IonIcon icon={phonePortraitOutline} color="success" style={{fontSize: "1.5rem"}}/>
                        </IonCol>
                        <IonCol size='11'>{marker.telefono}</IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size='9'>
                        </IonCol>
                        <IonCol size='3'>
                            <IonButton color='success' onClick={onDismiss}>
                                {t("artesanias.close-btn")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>
    );
};

export default MarkerInfoWindow;