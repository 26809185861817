import React, { useEffect, useRef, useState } from 'react';
import "./MapModal.css"

import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
  
} from '@ionic/react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { Geolocation } from '@capacitor/geolocation';

import {useTranslation} from 'react-i18next'

// Fixing the default marker icon issue with Leaflet in React
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MapModal: React.FC<MapModalProps> = ({ isOpen, onClose }) => {
  const mapRef = useRef<L.Map | null>(null);

  const [state, setstate] = useState({
    currentLocation: {
        lat:19.930342479375692,
        lng:-88.80599898791014
    }
  });

  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired');
  });
  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });

  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
  });

  useIonViewWillLeave(() => {
    console.log('ionViewWillLeave event fired');
  });

const GetCurrentPosition = async () => {
    const [t,i18n] = useTranslation("global");

    const coordinates = await Geolocation.getCurrentPosition({enableHighAccuracy:true});
    setstate({currentLocation:{
        lat: coordinates.coords.latitude,
        lng: coordinates.coords.longitude
    }});
    console.log('Current position:', coordinates);
    console.log('Language: ',i18n.language);
  };

  useEffect(() => {
    if (isOpen && mapRef.current) {
      const map = mapRef.current;
      setTimeout(() => {
        map.invalidateSize();
        map.setView([19.441221, -88.992000], 6);
      }, 100);
    }
  }, [isOpen]);


  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} onDidPresent={() => {
        if (mapRef.current) {
          setTimeout(() => {
            mapRef.current!.invalidateSize();
            mapRef.current!.setView([19.441221, -88.992000], 6);
          }, 100);
        }
      }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mapa</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <MapContainer
            center={[state.currentLocation.lat,state.currentLocation.lng]} zoom={6}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
          >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker position={[state.currentLocation.lat,state.currentLocation.lng]}>
              <Popup>
              KANTEMÓ, LA CUEVA DE LAS SERPIENTES COLGANTES<br /> BEEJ KAAX HA KANTEMO S.C. DE R.L.
              </Popup>
            </Marker>
        </MapContainer>
      </IonContent>
    </IonModal>
  );
};

export default MapModal;
