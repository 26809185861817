import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput,IonPage, IonRow, IonSelect, IonSelectOption, IonTitle,IonToolbar,useIonLoading, useIonRouter } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences'
import "./Login.css"
import Intro from '../components/Intro';
import SelectLang from '../components/selectLanguage';
import { useTranslation } from 'react-i18next';
import { body } from 'ionicons/icons';


const INTRO_KEY = 'intro-seen';

const Login: React.FC = () => {
    const [ t , i18n ] = useTranslation("global");
    useEffect(() => {
        const checkStorage = async () => {
            const seen = await Preferences.get({key: INTRO_KEY});
            setIntroSeen(seen.value === 'true');
        }
        checkStorage();
    }, []);

    const ChangeLanguage = (value : string) =>{
        i18n.changeLanguage(value);
    }

    const history = useHistory();
    const [present, dismiss] = useIonLoading();
    const [introSeen,setIntroSeen] = useState(false);

    const doLogin = async(event: any) => {
        event.preventDefault();
        await present('Iniciando sesión...');
        setTimeout(async()=>{
            dismiss();
            history.replace('/app/tabs');
        },2000);
    };

    const finishIntro = async() =>{
        console.log('FIN');
        setIntroSeen(true);
        Preferences.set({key:INTRO_KEY,value:'true'});
    }

    // const PopoverOptions = {
    //     component: <Flag country='AR'/>,
    //     cssClass : 'course-popover'
    //   }

    return (
        <>
        {!introSeen? (
            <Intro onFinish={finishIntro} />
        ): (
            <>
            <IonPage>
                <IonHeader color="light" class="ion-no-border ion-text-center">
                    <div className='padding-left-lng'>
                        <SelectLang iconColorClass='gray-icon'/>
                    </div>
                        <IonTitle className='title customLogin'>{t("header.title")}</IonTitle>
                </IonHeader>
                <IonContent scrollY={false}>
                            <form onSubmit={doLogin}>
                                <p className='ion-margin-horizontal text'>{t("login.login-des")}</p>
                                <div className='margenH'>    
                                    <IonInput
                                        aria-label="Email"
                                        placeholder={t("login.placeholder-email")}
                                        fill="outline"
                                        labelPlacement='floating'
                                        label={t("login.title-email")}
                                        type='email'
                                        className='Inputs-login'
                                    ></IonInput>
                                </div>
                                <div className='margenH'>  
                                    <IonInput
                                        aria-label="Password"
                                        placeholder={t("login.placeholder-pass")}
                                        fill="outline"
                                        labelPlacement='floating'
                                        label={t("login.title-pass")}
                                        type='password'
                                        className='ion-margin-top ion-margin-bottom Inputs-login'
                                    ></IonInput>
                                </div>
                                <br></br>
                                <IonButton 
                                    expand='block'
                                    size='default'
                                    className='botoncustom ion-text-capitalize'
                                    type='submit'>
                                        {t("login.button-login")}
                                </IonButton>
                                <div className='container'>
                                    <div className='line'></div>
                                        <span className='text'>o</span>
                                    <div className='line'></div>
                                </div>
                                <IonButton 
                                    expand='block'
                                    fill="outline"
                                    color='medium'
                                    size='default'
                                    className='botoncustom ion-text-capitalize'>
                                        {t("login.button-consult")}
                                </IonButton>
                            </form>
                </IonContent>
                <IonFooter id="footertransp"class="ion-no-border ion-padding ion-margin-bottom ion-text-center">
                        <p className='text margin-text-login'>
                            {t("login.question-account")}
                            <Link to="/register" className='link'>&nbsp;{t("login.suggestion-register")}</Link>
                        </p>
                </IonFooter>
            </IonPage>
            </>
        )}
        </>
    );
};

export default Login;