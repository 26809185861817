import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonModal, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import '../components/foodItems.css'
import FoodReceipts from '../components/foodReceipts'
import { arrowBack } from 'ionicons/icons';
import { FoodItemsProps } from '../interfaces';
import { useTranslation } from 'react-i18next';


function FoodItems( {nombre, descripcion,urlimagen, ingredientes, nombreIngrediente, imageIngrediente, estatusEndemico, descripcionEndemico} : FoodItemsProps ) {
  const [isOpen2, setIsOpen2] = useState(false);
  const [ t , i18n ] = useTranslation("global");
  {
    console.log(ingredientes)
  } 
  return (
    <>    
        <IonItem detail={true} lines='full' onClick={() => setIsOpen2(true)} /*routerLink="/ComidasPage"*/>
            <IonThumbnail slot="start" className='foodCard'>
                <img alt={"Silhouette of mountains"} src={urlimagen} />
            </IonThumbnail>
            <IonLabel>
                <h2>{nombre}</h2>
                <p>{descripcion}</p>
            </IonLabel>
        </IonItem>

        <IonModal isOpen={isOpen2} keepContentsMounted={true}>
          <IonHeader className='platillo-ion-header'>
            <IonToolbar className='name-header-modal1 toolbar-modal'>
              <IonImg className='header-modal-img'
                src={urlimagen}
                alt="..."
              ></IonImg>
              <IonTitle>{nombre}</IonTitle>
              <IonButtons className='button-close'>
                <IonButton onClick={() => setIsOpen2(false)}>
                    <IonIcon icon={arrowBack}/>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <p>
              {descripcion}
            </p>
            <h2>
              {t("restaurant.ingredients")}
            </h2> 
            <div className="ingredientes">
            {
              nombreIngrediente.map((element, index) => 
                <FoodReceipts
                  key={element}
                  element={element}
                  image={imageIngrediente[index]}
                  status={estatusEndemico[index]}
                  descripcion={descripcionEndemico[index]}
                />
                )
            }
            
            </div>

              {/* {
                const nombrePlat = ingredientes.nombre
              ingredientes.map(element => 
                <FoodReceipts
                  key={element[0].nombre}
                  element={element}
                />
                )
              } */}
          </IonContent>
        </IonModal>
    </>
  );
}
export default FoodItems;



