import React, { useState } from 'react';

import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonThumbnail,
  IonItem,
  IonLabel,
  IonList,
  IonFooter,
  useIonAlert,
  useIonRouter,
  IonPage,
  IonButtons,
  IonIcon,
  IonGrid,
  IonModal
} from '@ionic/react';
import './TouristicPlace.css';
import ReviewsModal from './ReviewsModal';
import MapModal from './MapModal';
import PhotosModal from './PhotosModal';
import ActivitiesModal from './ActivitiesModal';
import PersonalizationModal from './PersonalizationModal';
import OfflineModeModal from './OfflineModeModal';
import AccessibilityModal from './AccessibilityModal';

import { RouteComponentProps, useHistory} from 'react-router';
import { arrowBack,alarmSharp,linkSharp, medalSharp } from 'ionicons/icons';
import {useTranslation} from 'react-i18next';
import { changeLanguage } from 'i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { IonAccordion, IonAccordionGroup } from '@ionic/react';
import { randomInt } from 'crypto';


const TouristicPlace: React.FC = () => {
  const [t,i18n] = useTranslation("global");
  const [err,setErr] = useState<string>();
  const [present] = useIonAlert();
  const history = useHistory();
  const router = useIonRouter();
  const [showModal, setShowModal] = useState<string | null>(null);

  const [modalContent, setModalContent] = useState('');

  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const icons = ['Map', 'Reviews', 'Photos', 'Activities'];
  const footerOptions = ['Personalization', 'Offline Mode', 'Accessibility'];
  const attractions = ['RECORRIDO POR LAS CUEVAS', 'CAMPAMENTOS', 'ESPELEOLOGÍA'];
  const stories = [
    { title: 'Invitación', content: 'Es uno de los lugares más maeravillosos que he visitado. Ven a conocerlo.' },
    { title: 'Festejamos en grande', content: 'Una comida se convirtió en uno de los mejores festejos que hemos tenido.' }
  ];
  // max min min no +1

  const nolug = Math.floor(Math.random() * (3 - 1 + 1)) + 1;
  console.log('Num>',nolug);

  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false);

  const photos1 = [
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemo_5.jpg', description: 'Senderos escondidos' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemo_2.jpg', description: 'Fósiles' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemp_1.jpg', description: 'Exploración de cuevas' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemp_3.jpg', description: 'Caminatas en interior de cuevas' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemp_4.jpg', description: 'Acampar' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemo_ok.jpg', description: 'Deportes acuáticos' }
  ];

  const photos2 = [
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/LARIA_3.jpg', description: 'Paddle surf en aguas Yucatecas' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/RIA_1.jpg', description: 'Paseos' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/RIa_4.jpg', description: 'Observación de aves' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/RIA_5.jpg', description: 'Paseo en bote' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/RIA_5.jpg', description: 'Manglares' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/ria_2.jpg', description: 'Deportes acuáticos' }
  ];


  const photos3 = [
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/X-CANCHE_ok.jpg', description:'Tirolesa' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/XCANCHE_1.jpg', description: 'Paseos en bicicleta' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/hamacas_ok.jpg', description: 'Palapas' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/xcance_1.jpg', description: 'Visita acenotes' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/xcance_2.jpg', description: 'Paseos' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/xcance_3.jpg', description: 'Deportes acuáticos' },
    { url: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/xcance_ok.jpg', description:'Artesanias'},
    { url:'https://paraisos.wpengine.com/wp-content/uploads/2019/12/XCANCHE_3.jpg', description:'Nado en cenotes'}
  ];

  let photos = photos1;

  switch(nolug){
    case 0:
        case 1:
            photos = photos1;
            break;
    case 2:
        photos = photos2;
        break;
    case 3:
        photos = photos3;
        break;
  }

  const comunidad = [
    {texto: 'BEEJ KAAX HA KANTEMO S.C. DE R.L.'},
    {texto: 'SOCIEDAD COOPERATIVA DE PRODUCCION PESQUERA Y DE SERVICIOS TURISTICOS RUTA HACIA EL PROGRESO S.C. DE R.L.'},
    {texto: 'CENTRO ECOTURISTICO CENOTE X-CANCHE S.C. DE R.L.'},
    {texto: 'SOCIEDAD COOPERATIVA TURÍSTICA PESQUERA  JALTUN DE CELESTÚN S.C. DE R.L.'},
    {texto: 'ZAAZ KOOLEN HAA S.C. DE R.L.'},
    {texto: 'SERVICIOS TURISTICOS SAC AUA, S.C. DE R.L.'},
    {texto: 'SERVICIOS ECOTURISTICOS DE KIKIL S.C. DE R.L. DE C.V'}
  ];

  const sitio = [
    {texto: 'KANTEMÓ, LA CUEVA DE LAS SERPIENTES COLGANTES'},
    {texto: 'LA RÍA PROGRESO'},
    {texto: 'CENOTE ECOTURÍSTICO X’CANCHÉ'},
    {texto: 'JALTÚN DE CELESTÚN'},
    {texto: 'CENOTE YOKDZONOT'},
    {texto: 'SAC AUA'},
    {texto: 'CENOTE KIKIL'}
  ];

  const descripcion = [
    {texto:'Explora impresionantes cuevas, pasea en bici por la selva y convive con la comunidad maya. Muy cerca del poblado de Dziuché, se encuentra Kantemó; una comunidad maya conocida por sus cuevas. Un viaje a este lugar te permitirá conocer las serpientes que cuelgan de sus cavidades. Si eres un amante de los ofidios, entonces tienes que ver cómo las serpientes ratoneras tropicales, colgadas desde las rocas, se encuentran a la espera de cazar alguno de los miles de murciélagos que salen en las tardes. En las profundidades de las cuevas podrás ver fósiles, prueba de que este lugar fue parte del lecho marino en el pasado. Disfruta de este fascinante espectáculo de la naturaleza y mira el maravilloso mundo acuático que ahí habita: camarones, cochinillas, damas blancas y anguilas.'},
    {texto:'Súbete sobre un paddle surf y surca aguas yucatecas, explora la ría y sus manglares, mira cientos de aves volar y enamórate del lugar.Este paraíso se formó con el fin de convivir y disfrutar la belleza natural que ofrecen la ría, los manglares y los islotes sin dañar la naturaleza. Quienes aquí laboran, han encontrado en la conservación y cuidado de este medio ambiente su forma de vida.La mejor manera de conocerlo es explorar la costa de Yucatán y su vida silvestre; la mezcla de agua dulce y salada permite el desarrollo de árboles de mangle, aves costeras, cangrejos, lagartos y mucho más; mientras los guías locales hablan sobre el importante papel que juega este espacio natural para todos los seres vivos que lo habitan. Con una hermosa panorámica de la ría, es posible adentrarse por túneles naturales y relajarse con la paz que aquí se experimenta. ¿Imaginas remar sentado en kayak o parado en un paddle?'},
    {texto:'Desciende a rapel un cenote, lánzate de una liana y grita como Tarzán, pasea en bici por la selva; en la noche, disfruta una fogata a la luz de la luna. Es un destino que al mismo tiempo es para aventureros y para quienes buscan paz. Quien decida conocer este paradisiaco lugar yucateco, encontrará una vista esplendorosa del cenote X-Canché -desde las alturas- al momento de lanzarse de la tirolesa; sentirá la temperatura de sus aguas al nadar en ellas; conocerá la vegetación del interior al descender a rapel o experimentará la adrenalia al columpiarse en una liana, como Tarzán. Los amantes de la naturaleza podrán recorrer, en bici o a pie, los senderos trazados del rededor y al tiempo observar aves, reconocer árboles y saber del trabajo en la milpa maya, entre otras actividades ecoturísticas. Para el ansioso por relajarse, aquí podrá descansar al ritmo mecedor de una hamaca, disfrutar la noche en una cabaña o acampar a la luz de una fogata.'}
  ];

  const servicios1 = [
    {texto:'Avistamiento de aves'},
    {texto:'Bicicleta'},
    {texto:'Hamacas'},
    {texto:'Hospedaje en cabañas'},
    {texto:'Observación de flora y fauna'},
    {texto:'Palapas'},
    {texto:'Paseo en canoa'},
    {texto:'Recorrido guiado'},
    {texto:'Restaurante'},
    {texto:'Senderismo'}
  ];

  const servicios2 = [
    {texto:'Avistamiento de aves'},
    {texto:'Kayak'},
    {texto:'Mirador'},
    {texto:'Observación de flora y fauna'},
    {texto:'Paddle board'},
    {texto:'Paseo en lancha'},
    {texto:'Recorrido guiado'},
    {texto:'Restaurante'}
  ];

  const servicios3 = [
    {texto:'Bicicleta'},
    {texto:'Campismo'},
    {texto:'Cenotes'},
    {texto:'Fogata'},
    {texto:'Hamacas'},
    {texto:'Hospedaje en cabañas'},
    {texto:'Nado'},
    {texto:'Observación de flora y fauna'},
    {texto:'Palapas'},
    {texto:'Rapel'},
    {texto:'Recorrido guiado'},
    {texto:'Recorrido a pie'},
    {texto:'Restaurante'},
    {texto:'senderismo'},
    {texto:'Tirolesa'}
  ];

  let servicios = servicios1;

  switch(nolug){
    case 0:
        case 1:
            servicios = servicios1;
            break;
    case 2:
        servicios = servicios2;
        break;
    case 3:
        servicios = servicios3;
        break;
  }

  const datos_contacto1 = [
    {
        ruta:'Sigue la carretera 307, Cancún – Felipe Carrillo Puerto. Al llegar a Carrillo Puerto, continúa hacia José María Morelos hasta Dziuché, y a solo 2 km se encuentra Kantemó.', 
        telefono:'997 977 4920',
        otro_telefono: '997 977 8387',
        tel_movil: '',
        sitio_web:'',
        redes_sociales:{facebook:'https://www.facebook.com/KantemoPrincipal/', X:'', instagram:'', pinterest:'', tiktok:'', snapchat:'', youtube:''},
        correos:{principal:'bejkaxha@yahoo.com.mx', secundario:'', terciario:''}
    }
]

const datos_contacto2 = [
    {
        ruta:'Desde Mérida,  la entrada de Progreso se encuentra muy cerca de la glorieta. Lleva alrededor de treinta minutos estar en este paraíso.', 
        telefono:'999 340 0459',
        otro_telefono: '997 977 8387',
        tel_movil: '',
        sitio_web:'',
        redes_sociales:{facebook:'https://www.facebook.com/LaRiadeProgreso/', X:'', instagram:'', pinterest:'', tiktok:'', snapchat:'', youtube:''},
        correos:{principal:'lariaprogreso@hotmail.com', secundario:'', terciario:''}
    }
]

const datos_contacto3 = [
    {
        ruta:'Este sitio se ubica muy cerca de la zona arqueológica de Ek Balam, al norte de Valladolid.', 
        telefono:'985 100 9915',
        otro_telefono: '985 107 4774',
        tel_movil: '',
        sitio_web:'http://www.ekbalam.com.mx/cenote-xcanche/',
        redes_sociales:{facebook:'https://www.facebook.com/Cenote-Ecoturistico-X-Canche-Oficial-2101293130082362/', facebook1:'https://www.facebook.com/xcanche.cenote.7',X:'', instagram:'', pinterest:'', tiktok:'', snapchat:'', youtube:''},
        correos:{principal:'benito-x-canche@hotmail.com', secundario:'benedictocan66@outlook.com', terciario:''}
    }
]

let datos_contacto = datos_contacto1;

switch(nolug){
  case 0:
      case 1:
          datos_contacto = datos_contacto1;
          break;
  case 2:
      datos_contacto = datos_contacto2;
      break;
  case 3:
      datos_contacto = datos_contacto3;
      break;
}


  const [isReviewsModalOpen, setIsReviewsModalOpen] = useState(false);

  const reviews = [
    { title: 'Es un lugar hermoso', author: 'Juan Dominguez', text: 'Vine y salí maravillado y feliz.', rating: 5 },
    { title: 'Una experiencia muy grata', author: 'Jorge Armenta', text: 'Un lugar lleno de emociones y aventuras. Y de adrenalina', rating: 4 },
    { title: 'Me gustó', author: 'Alicia Jimenez', text: 'Lo mejor que he visitado en años', rating: 3 },
    // Add more reviews as needed
  ];

  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = useState(false);

  const activities1 = [
    { title: 'RECORRIDO POR LAS CUEVAS', description: 'Experimenta un espectáculo en la oscuridad total, en donde las serpientes colgantes esperan la salida de los murciélagos, al caer la noche. Para ser parte de esta vivencia, es preciso usar cascos con lámpara, guantes y cubre bocas, los guías te los proporcionarán.', imageUrl: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemp_3.jpg' },
    { title: 'PASEO EN CANOA POR LA LAGUNA CHICHANKANAB', description: 'Por la noche, embárcate en una canoa y durante el paseo podrás observar cocodrilos moreletti, posiblemente también tortugas y peces. Observa a tu alrededor la vegetación compuesta de mangle botoncillo blanco y de sabana. En el recorrido verás aves acuáticas, como garzas, cormorán, anhinga y martín pescador.', imageUrl: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemo_ok.jpg' },
    { title: 'SENDERO INTERPRETATIVO', description: 'Aventúrate en un recorrido en bicicleta, de aproximadamente 3.5 km, entre los senderos interpretativos de la selva; a tu paso, observa gran cantidad de aves residentes y migratorias, además de flora típica de la selva mediana caducifolia como el chechén y las orquídeas. Si lo prefieres, este recorrido también puedes hacerlo a pie.', imageUrl: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/kantemo_5.jpg' },
    // Add more activities as needed
  ];

  const activities2 = [
    { title: 'EXPLORACIÓN DE LA RÍA', description: 'Explora y descubre los secretos de este ecosistema costero a bordo de una lancha; recorre los mejores puntos del área de la mano de los guías, quienes te contarán acerca de la biodiversidad de este lugar.', imageUrl: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/ria_1.jpg' },
    { title: 'OBSERVACIÓN DE AVES', description: 'Observa e identifica las diversas aves que aquí habitan: flamencos, garzas, cormoranes, pelícanos y muchas más son parte del espectáculo. Verlas volar es un espectáculo increíble. Esta actividad se realiza con ayuda de un guía especializado.', imageUrl: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/RIa_4.jpg' },
    { title: 'AVENTURA EN PADDLE', description: 'Vive la experiencia sobre un paddle surf, de pie sobre una ligera tabla; y rema en este hábitat misterioso y lleno de vida. Balancéate bien y mantén el equilibrio, mientras te aventuras para admirar desde otra perspectiva, la belleza natural y hermosos paisajes que encuentras en la ría.', imageUrl: 'https://paraisos.wpengine.com/wp-content/uploads/2019/12/ria_2.jpg' },
    // Add more activities as needed
  ];

  const activities3 = [
    { title:'NADAR EN UN CENOTE, PARAÍSO NATURAL', description:'Atrévete a nadar en el gran y hermoso cenote X-Canché; observa los peces que viven allí y explora todos sus rincones. Admira este cenote abierto rodeado de naturaleza, con gran variedad de flora y fauna por descubrir. Aventúrate en esta gran fuente de agua, lugar sagrado para los mayas.',imageUrl:'https://paraisos.wpengine.com/wp-content/uploads/2019/12/XCANCHE_3.jpg'},
    { title:'EXPLORAR LOS ALREDEDORES', description:'Elige tus aventuras favoritas y disfruta del cenote y la selva de una manera diferente; tienes varias opciones: la tirolesa que cruza encima del cenote o la otra tirolesa que termina directo en el agua; descenso lento a rapel por una pared vertical hasta llegar al agua; lanzarse de una liana y dar el mejor grito de Tarzán; senderismo por la selva en bici o a pie, y aprendizaje de aves, árboles, milpas y más.',imageUrl:'https://paraisos.wpengine.com/wp-content/uploads/2019/12/XCANCHE_1.jpg'},
    { title:'ADMIRAR LA NATURALEZA', description:'Siente la paz del rededor en el área de hamacas; conoce el cenote de noche, al atardecer o al amanecer; disfruta del cielo yu­cateco con una fogata para acompañarte.',imageUrl:'https://paraisos.wpengine.com/wp-content/uploads/2019/12/X-CANCHE_ok.jpg'}
  ]

  let activities = activities1;

  switch(nolug){
    case 0:
        case 1:
            activities = activities1;
            break;
    case 2:
        activities = activities2;
        break;
    case 3:
        activities = activities3;
        break;
  }

  const localizacion = [
    {texto:'Dziuché, Quintana Roo'},
    {texto:'Progreso, Yucatan'},
    {texto:'Ek Balam, Yucatan'}
  ];

  const toggleDetails = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  const handleModalOpen = (content: string) => {
    setShowModal(content);
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  const navigate = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    router.push('/app/tabs');
  };


  return (
    <IonPage>
        <IonHeader>
            <IonToolbar className='custom'>
                <IonButtons slot='start'>
                    <IonButton onClick={(event) => navigate(event)} color='light'>
                        <IonIcon icon={arrowBack} size='medium'></IonIcon> 
                    </IonButton>
                </IonButtons>
                <IonTitle className="nombre-marca">Mexico Profundo</IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent>
        {/* Hero Image */}
        <div className="hero-image">
          <img src={photos[0]['url']} alt="Hero Image" />
        </div>

        {/* Location Name and Tagline */}
        <div className="location-info">
          <h3>{sitio[nolug-1]['texto']}</h3>
          <h4>{comunidad[nolug-1]['texto']}</h4>
          <h4>{localizacion[nolug-1]['texto']}</h4>
          <p>{descripcion[nolug-1]['texto']}</p>
        </div>

        {/* Quick Access Icons */}
        <IonGrid>
          <IonRow>
            <IonCol key={1} size="3">
                <IonButton expand="full" className="quick-access-icon" onClick={() => handleModalOpen("Map")}>Mapa</IonButton>
            </IonCol>
            <IonCol key={2} size="3">
                <IonButton expand="full" className="quick-access-icon"  onClick={() => setIsReviewsModalOpen(true)}>Comentarios</IonButton>
            </IonCol>
            <IonCol key={3} size="3">
                <IonButton expand="full" className="quick-access-icon"  onClick={() => setIsPhotosModalOpen(true)}>Fotos</IonButton>
            </IonCol>
            <IonCol key={4} size="3">
                <IonButton expand="full" className="quick-access-icon"  onClick={() => setIsActivitiesModalOpen(true)}>Actividades</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Top Attractions/Experiences */}
        <div className="attractions">
          <h2>Mejores experiencias o atracciones</h2>
          <div className="attractions-scroll">
            {attractions.map((attraction, index) => (
              <IonCard key={index}>
                <img src={photos[index].url} alt="Attraction" />
                <IonCardHeader>
                  <IonCardTitle>{photos[index].description}</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            ))}
          </div>
        </div>

        {/* Stories Section */}
        <div className="stories">
          <h2>Historias</h2>
          <IonList>
            {stories.map((story, index) => (
              <IonItem key={index}>
                <IonThumbnail slot="start">
                  <img src={photos[index].url} alt="Story" />
                </IonThumbnail>
                <IonLabel>
                  <h3>{story.title}</h3>
                  <p>{story.content}</p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </div>

        {/* Expandable Details Section */}
        {/*
        <div className="details">
          <h2 onClick={toggleDetails}>Expandable Details Section (Read More)</h2>
          {detailsExpanded && (
            <div>
              <p>Here are the details...</p>
            </div>
          )}
        </div>
        */}

        {/* Modals */}
        <ReviewsModal
          isOpen={isReviewsModalOpen}
          onClose={() => setIsReviewsModalOpen(false)}
          reviews={reviews}
        />

        <MapModal isOpen={showModal === 'Map'} onClose={handleModalClose} />
        <PhotosModal
          isOpen={isPhotosModalOpen}
          onClose={() => setIsPhotosModalOpen(false)}
          photos={photos}
        />        
        <ActivitiesModal
          isOpen={isActivitiesModalOpen}
          onClose={() => setIsActivitiesModalOpen(false)}
          activities={activities}
        />

        {/*
        <PersonalizationModal isOpen={showModal === 'Personalization'} onClose={handleModalClose} />
        <OfflineModeModal isOpen={showModal === 'Offline Mode'} onClose={handleModalClose} />
        <AccessibilityModal isOpen={showModal === 'Accessibility'} onClose={handleModalClose} />
        */}

      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              {footerOptions.map((option, index) => (
                <IonCol key={index}>
                  <IonButton
                    expand="full"
                    fill="outline"
                    onClick={() => handleModalOpen(option)}
                  >
                    {option}
                  </IonButton>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default TouristicPlace;
