import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage
} from '@ionic/react';

import Experience from '../components/ProgressRewards'
import PromotionCard from '../components/PromotionCard';
import './Tab3.css';
import dataSitio from '../data/sitio-interes.json'

const Tab3: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className='ion-header-tab3'>
        <Experience
          puntos = {55}
        ></Experience>
      </IonHeader>
      <IonContent>

      {dataSitio.map(sitio =>
          <PromotionCard
              key = {sitio.nombre}
              nombre ={sitio.nombre}
              imageUrl ={sitio.urlImagen}
              promotion = {sitio.promocion}
              tipo = {sitio.tipo}
          />  
        )}
        
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
