import React from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react';
import './PhotosModal.css';

interface Photo {
  url: string;
  description?: string;
}

const photos: Photo[] = [
    { url: 'https://example.com/photo1.jpg', description: 'Photo 1' },
    { url: 'https://example.com/photo2.jpg', description: 'Photo 2' }
  ];

interface PhotosModalProps {
  isOpen: boolean;
  onClose: () => void;
  photos: Photo[];
}

const PhotosModal: React.FC<PhotosModalProps> = ({ isOpen, onClose, photos}) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Fotos</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="photos-modal-content">
        <IonGrid className="photos-modal-grid">
          <IonRow>
            {photos.map((photo, index) => (
              <IonCol size="6" key={index} className="photo-col">
                <IonImg src={photo.url} alt={photo.description} className="photo-img" />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default PhotosModal;
